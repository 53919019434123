import { 
        PN_PLAN_SUCCESS,
        PN_PLAN_FAIL,
        PN_ACTIVE_TAB,
        PN_DISPENSE_DETAIL_SUCCESS,
        PN_DISPENSE_DETAIL_FAIL,
        PN_DISPENSE_SUCCESS,
        PN_DISPENSE_FAIL,
        PN_PLAN_SUSPEND_SUCCESS,
        PN_PLAN_SUSPEND_FAIL,
        ADD_PN_PLAN_SUCCESS,
        ADD_PN_PLAN_FAIL,
        PN_SUSPENDED_PLAN_SUCCESS,
        PN_SUSPENDED_PLAN_FAIL,
        PN_DISPENSE_HISTORY_SUCCESS,
        PN_DISPENSE_HISTORY_FAIL,
        PN_EDIT,
        UPDATE_PN_PLAN_SUCCESS,
        UPDATE_PN_PLAN_FAIL
    } from './Constants';
import axios from 'axios';
import { withHeader } from '../../../../service/ApiConfig';
import * as webConst from '../../../../utils/WebConstant';
const axiosDefaults = require('axios/lib/defaults');
axiosDefaults.baseURL = withHeader.baseURL;
axiosDefaults.headers = withHeader.headers;
axiosDefaults.timeout = withHeader.timeOut;

export const getPnMedicinePlanRequest = (value) => {
    return dispatch => {
        axios.get(`${webConst.PN_PLAN}?`+value)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(pnPlanSuccessResult(response.data.data, response.status));
                }
                else {
                    dispatch(pnPlanFailResult(response.data.data.message, response.status));
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    dispatch(pnPlanFailResult(error.response.message,error.response.status));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    }
};


export const getPnDispenseDetailRequest = (value) => {
    return dispatch => {
        axios.get(`${webConst.PN_PLAN}/`+value, )
            .then((response) => {
                if (response.status === 200) {
                    dispatch(pnDispenseDetailSuccessResult(response.data.data, response.status));
                }
                else {
                    dispatch(pnDispenseDetailFailResult(response.data.message, response.status));
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    dispatch(pnDispenseDetailFailResult(error.response.message,error.response.status));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    }
};

export const saveDispenseDosageRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.PN_DOSAGE}`, value)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(pnDispenseSuccessResult(response.data.data, response.status));
                }
                else {
                    dispatch(pnDispenseFailResult(response.data.message, response.status));
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    dispatch(pnDispenseFailResult(error.response.message,error.response.status));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    }
};

export const suspendPnMedicineRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.PN_SUSPEND}`, value)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(suspendPnMedicineSuccessResult(response.data.data, response.status));
                }
                else {
                    dispatch(suspendPnMedicineFailResult(response.data.message, response.status));
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    dispatch(suspendPnMedicineFailResult(error.response.message,error.response.status));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    }
};

export const addPnMedicinePlanRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.PN_PLAN}`, value)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(addPnPlanSuccessResult(response.data.data, response.status));
                }
                else {
                    dispatch(addPnPlanFailResult(response.data.message, response.status));
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    dispatch(addPnPlanFailResult(error.response.message,error.response.status));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    }
};

export const updatePnMedicinePlanRequest = (id,value) => {
    return dispatch => {
        axios.put(`${webConst.PN_PLAN}/`+id, value)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(updatePnPlanSuccessResult(response.data.data, response.status));
                }
                else {
                    dispatch(updatePnPlanFailResult(response.data.message, response.status));
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2x
                    dispatch(updatePnPlanFailResult(error.response.message,error.response.status));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    }
};

export const getSuspendedPnMedicinePlanHistoryRequest = (value) => {
    return dispatch => {
        axios.get(`${webConst.PN_SUSPENDED_PLAN_HISTORY}?`+value)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(suspendedPnPlanSuccessResult(response.data.data, response.status));
                }
                else {
                    dispatch(suspendedPnPlanFailResult(response.data.message, response.status));
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    dispatch(suspendedPnPlanFailResult(error.response.message,error.response.status));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    }
};


export const getPnDispenseHistoryRequest = (value) => {
    return dispatch => {
        axios.get(`${webConst.PN_DOSAGE}?`+value)
            .then((response) => {
                console.log("getPnDispenseHistoryRequest",response.data);
                if (response.status === 200) {
                    dispatch(pnDispenseHistorySuccessResult(response.data.data, response.status));
                }
                else {
                    dispatch(pnDispenseHistoryFailResult(response.data.message, response.status));
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2x
                    dispatch(pnDispenseHistoryFailResult(error.response.message,error.response.status));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    }
};

/*
    API RESULT
 */

export const pnPlanSuccessResult = (success, status) => ({
    type: PN_PLAN_SUCCESS,
    success,
    status
});

export const pnPlanFailResult = (error, status) => ({
    type: PN_PLAN_FAIL,
    error,
    status
});

export const setPnActiveTab = (pnType) => ({
    type: PN_ACTIVE_TAB,
    pnType
});

export const pnDispenseDetailSuccessResult = (success, status) => ({
    type: PN_DISPENSE_DETAIL_SUCCESS,
    success,
    status
});

export const pnDispenseDetailFailResult = (error, status) => ({
    type: PN_DISPENSE_DETAIL_FAIL,
    error,
    status
});

export const pnDispenseSuccessResult = (success, status) => ({
    type: PN_DISPENSE_SUCCESS,
    success,
    status
});

export const pnDispenseFailResult = (error, status) => ({
    type: PN_DISPENSE_FAIL,
    error,
    status
});

export const pnDispenseHistorySuccessResult = (success, status) => ({
    type: PN_DISPENSE_HISTORY_SUCCESS,
    success,
    status
});

export const pnDispenseHistoryFailResult = (error, status) => ({
    type: PN_DISPENSE_HISTORY_FAIL,
    error,
    status
});


export const suspendPnMedicineSuccessResult = (success, status) => ({
    type: PN_PLAN_SUSPEND_SUCCESS,
    success,
    status
});

export const suspendPnMedicineFailResult = (error, status) => ({
    type: PN_PLAN_SUSPEND_FAIL,
    error,
    status
});

export const addPnPlanSuccessResult = (success, status) => ({
    type: ADD_PN_PLAN_SUCCESS,
    success,
    status
});

export const addPnPlanFailResult = (error, status) => ({
    type: ADD_PN_PLAN_FAIL,
    error,
    status
});


export const updatePnPlanSuccessResult = (success, status) => ({
    type: UPDATE_PN_PLAN_SUCCESS,
    success,
    status
});

export const updatePnPlanFailResult = (error, status) => ({
    type: UPDATE_PN_PLAN_FAIL,
    error,
    status
});


export const suspendedPnPlanSuccessResult = (success, status) => ({
    type: PN_SUSPENDED_PLAN_SUCCESS,
    success,
    status
});

export const suspendedPnPlanFailResult = (error, status) => ({
    type: PN_SUSPENDED_PLAN_FAIL,
    error,
    status
});

export const setEditPn = (pn) => ({
    type: PN_EDIT,
    pn
});