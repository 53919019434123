import React, { Component, Fragment } from 'react';
// import Select from "react-select";
import { Tabs, Tab, Table, FormGroup, Label, Input, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './PN.scss';
import * as webConst from '../../../utils/WebConstant';
import * as routes from '../../../utils/RouteConstant';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import backArrow from '../../../images/back-arrow.svg';
import { getPnMedicinePlanRequest, setPnActiveTab, suspendPnMedicineRequest, setEditPn } from '../../../containers/Medicines/Pn/PnPlanContainer/action';
import { PN_PLAN_SUCCESS, PN_PLAN_SUSPEND_SUCCESS, PN_PLAN_SUSPEND_FAIL, PN_EDIT } from '../../../containers/Medicines/Pn/PnPlanContainer/Constants';
import moment from "moment";
import AddPN from '../../Medicine/PN/AddPN';
import PNDispense from './PNDispense/PNDispense';
import PNDispenseHistory from './PNDispenseHistory/PNDispenseHistory';
import SuspendedPN from './SuspendedPN/SuspendedPN';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { Cube } from 'react-preloaders2';
import InfiniteScroll from 'react-infinite-scroll-component';
import editIcon from '../../../images/edit-icon.svg';
import viewIcon from '../../../images/view-icon.svg';
import medicineIcon from '../../../images/green-medicine.svg';
class PN extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPnLoading: true,
			clientId: "",
			clientName: "",
			clientCPR: "",
			clientPhoto: "",
			pnActiveTab: "active-pn",
			isLoadedDatatable: 0,
			pns: [],
			pageSize: 20,
			pageNumber: 0,
			editPn:""
		};
	}

	componentDidMount() {
		let _this = this;
		let client = webConst.getClient();
		let clientId = client && typeof client !== 'undefined' && typeof client.client_id !== 'undefined' ? client.client_id : 0;
		if (clientId > 0) {
			let clientName = client && typeof client !== 'undefined' && typeof client.client_name !== 'undefined' ? client.client_name : 0;
			let cpr = client && typeof client !== 'undefined' && typeof client.client_socialid !== 'undefined' ? client.client_socialid : 0;
			let photo = client && typeof client !== 'undefined' && typeof client.photo !== 'undefined' ? client.photo : 0;
			this.setState({ clientId: clientId, clientName: clientName, clientCPR: cpr, clientPhoto: photo, isPnLoading: true });
			this.getActivePn();
			this.activeTab("active-pn");
		}
		else {
			webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.select-client-message" />);
			this.props.history.push(routes.HOME_ROUTE);
		}
	}

	componentDidUpdate(prevProps) {
		const { pnSuccess, pnStatus, pnType, pnActiveTab } = this.props;
		if (this.state.pnActiveTab !== pnActiveTab && pnActiveTab !== "") {
			if (pnActiveTab !== "") {
				this.setState({ pnActiveTab: pnActiveTab });
			}

			if (prevProps.pnActiveTab !== "" && pnActiveTab === "active-pn") {
				this.getActivePn();
				this.activeTab("active-pn");
				this.setState({ isPnLoading: true });
			}
		}

		if (pnStatus === 200) {
			if (pnType === PN_PLAN_SUCCESS) {
				if (prevProps.pnSuccess !== pnSuccess) {
					this.setState({
						isPnLoading: false,
					});

					if (pnSuccess.pns && pnSuccess.pns.length) {
						this.setState({ pns: pnSuccess.pns });
						// this.editRow(pnSuccess.pns[0]);
						// console.log(pnSuccess.pns[0]);
					}
				}
			}
			
			if (pnType === PN_PLAN_SUSPEND_SUCCESS) {
				if (prevProps.pnSuccess !== pnSuccess) {
					this.setState({
						isPnLoading: false,
					});
					webConst.toaster(webConst.TOAST_SUCCESS, <Translate id="medicine.suspend-message" />);
				}
			}
		}

		if (pnStatus === 400) {
			if (this.state.isPnLoading) {
				this.setState({
					isPnLoading: false,
				});
			}

			if (pnType === PN_PLAN_SUSPEND_FAIL) {
				webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.something-wrong" />);
			}

			if (this.state.activePnRecords.length > 0)
				this.setState({ activePnRecords: [], activePnTotalRecord: 0 });
		}
	}

	getActivePn = () => {
		let pageNumber = this.state.pageNumber ? this.state.pageNumber : 0;
		pageNumber = pageNumber + 1;
		if ((this.state.totalRecord > this.state.pns.length) || pageNumber == 1) {
			if (!this.state.isPnLoading) {
				this.setState({ 'isPnLoading': true, 'isLoading': true });
			}
			const params = new URLSearchParams();
			params.append('client_id', webConst.getClientId());
			params.append('page', pageNumber);
			params.append('status', '0');
			params.append('limit', this.state.pageSize);
			this.props.getPnMedicinePlanRequest(params);
			this.setState({ pageNumber: pageNumber });
		}
	}

	activeTab = (id) => {
		this.props.setPnActiveTab(id);
	}

	dispenseMedicine = (record) => {
		this.props.setPnActiveTab("pn-dispense");
		this.props.history.push({
			pathname: routes.PN_DISPENSE_ROUTE + "/" + record.id,
			state: record
		});
	}

	editRow = (pn) => {
		this.activeTab("add-pn");
		this.props.setEditPn(pn);
		this.setState({ editPn: pn });
	}

	suspendMedicine = (pn) => {
		let pnId = pn.id;
		let _this = this;
		let options = {
			closeOnEscape: true,
			closeOnClickOutside: true,
			customUI: ({ onClose }) => {
				return (
					<Card className="pt-3 p-2">
						<Card.Body>
							<Card.Text className='pb-2'>
								<strong>{"Do you want to suspend this pn medicine plan?"}</strong>
							</Card.Text>
							<div className="d-flex flex-row bd-highlight mb-3">
								<div className="p-2 bd-highlight">
									<button className='validi-theme-btn align-baseline'
										onClick={() => {
											if (pnId) {
												const params = new URLSearchParams();
												params.append('id', pnId);
												_this.props.suspendPnMedicineRequest(params);
												onClose();
												_this.setState({ pns: [] });
												const rows = _this.state.pns.filter(item => item.id !== pnId)
												_this.setState({ pns: rows });
											}
										}}
									> Ja</button>
								</div>
								<div className="p-2 bd-highlight"><button className='validi-theme-btn align-middle'
									onClick={() => {
										onClose();
									}}>Ingen</button></div>
							</div>
						</Card.Body>
					</Card>
				);
			},
			overlayClassName: "overlay-custom"
		};
		confirmAlert(options);
	}

	render() {
		return (
			<div className="validi-main-layout validi-pn-plan-layout-main">
				<div>
					<div className="container">
						<div className="validi-back-btn-wrapper">
							<Link to={routes.MEDICINE_PLAN_ROUTE}>
								<i>
									<img src={backArrow} />
								</i>
								<Translate id="medicine.back-to-medicine-plan" />
							</Link>
						</div>
						<h1 className="main-title"><Translate id="medicine.pn-plan" /></h1>
						<div className="validi-medicine-wrapper">
							<div className="validi-inner-header-bar">
								<div className="validi-avatar-details-wrepper">
									<div className="validi-avatar-img">
										{this.state.clientPhoto ? <img src={this.state.clientPhoto} alt="" /> : ""}
									</div>
									<div className="validi-avatar-details">
										<h5 className="validi-user-main-title">{this.state.clientName ? this.state.clientName : ""}</h5>
										<p className="validi-paragraph"><Translate id="client-goal.cpr-number" /> : {this.state.clientCPR ? this.state.clientCPR : ""}</p>
									</div>
								</div>
								<div className="validi-select-input">

								</div>
							</div>
							<div className="validi-tabbar-wrapper">
								<Tabs id="pn-tabs" defaultActiveKey={this.state.pnActiveTab} activeKey={this.state.pnActiveTab} onSelect={(id) => { this.activeTab(id) }}>
									<Tab eventKey="active-pn" title={<Translate id="medicine.pn-plan" />}>
										<div className="">
											<div className="validi-tabbar-inner-header">
												<div className="">
													<h4 className="validi-subtitle"><Translate id="medicine.pn-plan" /></h4>
												</div>
											</div>
											<div className="validi-table-data-wrapper">
												<div className='table-responsive-md'>
													<InfiniteScroll
														dataLength={this.state.pns && this.state.pns.length ? this.state.pns.length : 0}
														next={() => this.getActivePn}
														hasMore={this.state.isMoreRecord}
														loader={this.state.isPnLoading ? <h4><Translate id="general.loading" /></h4> : ""}
													>
														{this.state.pns && Object.keys(this.state.pns).length ?
															<Table responsive="md">
																<thead>
																	<tr>
																		<th>#</th>
																		<th><Translate id="general.doctor" /></th>
																		<th><Translate id="general.medicine" /></th>
																		<th><Translate id="general.start-date" /></th>
																		<th><Translate id="general.total" /></th>
																		<th><Translate id="general.created-by" /></th>
																		<th><Translate id="general.note" /></th>
																		<th><Translate id="general.action" /></th>
																	</tr>
																</thead>
																<tbody>
																	{this.state.pns.map((pn, index) => (
																		<tr key={index}>
																			<td className="validi-date-text">{index + 1}</td>
																			<td className='text-capitalize'>{pn.doctor.name}</td>
																			<td className='text-capitalize'>{pn.medicine.name}</td>
																			<td>{pn.start_date}</td>
																			<td>{pn.initial_amount} {webConst.shortType(pn.medicine_type.medicine_type)}</td>
																			<td className='text-capitalize'>{pn.user.name}</td>
																			<td>{pn.note}</td>
																			<td className='text-right'>
																				<div className="validi-active-icons-wrapper">
																					<i onClick={() => this.editRow(pn)}><img src={editIcon} alt="edit Pn" /></i>
																					<i onClick={() => this.dispenseMedicine(pn)}><img src={medicineIcon} className='icon' alt="medicine-icon" /></i>
																					<i onClick={() => this.suspendMedicine(pn)} className='fa fa-ban text-danger'></i>
																					{/* <i onClick={() => this.viewPlan(pn)}><img src={viewIcon} alt="view Pn" /></i> */}
																				</div>
																			</td>
																		</tr>
																	))}
																</tbody>
															</Table>
															: ""
														}
													</InfiniteScroll>
													{
														this.state.pns && this.state.pns.length === 0 ?
															<div className='alert alert-danger'>
																<Translate id="medicine.detox-plan"></Translate> <Translate id="alert-message.not-found"></Translate>
															</div>
															: ""
													}
												</div>
											</div>
										</div>
									</Tab>
									<Tab eventKey="add-pn" title={<Translate id="medicine.add-pn-plan" />}>
										<AddPN />
									</Tab>
									<Tab eventKey="pn-dispense-history" title="PN Dispense histories">
										<PNDispenseHistory />
									</Tab>
									<Tab eventKey="pn-suspended" title="Suspended PN plan">
										<SuspendedPN />
									</Tab>
								</Tabs>
							</div>
						</div>
						<Cube customLoading={this.state.isPnLoading} color={'#66BB6A'} background="rgba(255,255,255,0.6)" />
					</div>
				</div>
			</div>
		)
	}
}

PN.defaultProps = {
	clientId: '',
	pnActiveTab: "active-pn",
	pnType: "",
	pnSuccess: "",
	pnFail: "",
	pnEdit: ""
};

PN.propTypes = {
	clientId: PropTypes.any,
	pnActiveTab: PropTypes.any,
	pnType: PropTypes.any,
	pnSuccess: PropTypes.any,
	pnFail: PropTypes.any,
	pnEdit: PropTypes.any
};

function mapStateToProps(state) {
	return {
		clientId: state.appLayout.clientId ? state.appLayout.clientId : "",
		pnActiveTab: state.pnPlan.pnActiveTab,
		pnSuccess: state.pnPlan.success,
		pnFail: state.pnPlan.fail,
		pnType: state.pnPlan.type,
		pnStatus: state.pnPlan.status,
		pnEdit: state.pnPlan.editPn
	};
}

const mapDispatchToProps = {
	getPnMedicinePlanRequest,
	setPnActiveTab,
	suspendPnMedicineRequest,
	setEditPn
};

export default connect(mapStateToProps, mapDispatchToProps)(PN);
