export const JOURNAL_TYPE_SUCCESS = 'JOURNAL_TYPE_SUCCESS';
export const JOURNAL_TYPE_FAIL = 'JOURNAL_TYPE_FAIL';

export const ADD_JOURNAL_TYPE_SUCCESS = 'ADD_JOURNAL_TYPE_SUCCESS';
export const ADD_JOURNAL_TYPE_FAIL = 'ADD_JOURNAL_TYPE_FAIL';

export const UPDATE_JOURNAL_TYPE_SUCCESS = 'UPDATE_JOURNAL_TYPE_SUCCESS';
export const UPDATE_JOURNAL_TYPE_FAIL = 'UPDATE_JOURNAL_TYPE_FAIL';

export const DELETE_JOURNAL_TYPE_SUCCESS = 'DELETE_JOURNAL_TYPE_SUCCESS';
export const DELETE_JOURNAL_TYPE_FAIL = 'DELETE_JOURNAL_TYPE_FAIL';