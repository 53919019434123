import { MESSAGE_SUCCESS, MESSAGE_FAIL } from './Constants';
import axios from 'axios';
import { withHeader } from '../../../service/ApiConfig';
import * as webConst from '../../../utils/WebConstant';
const axiosDefaults = require('axios/lib/defaults');
axiosDefaults.baseURL = withHeader.baseURL;
axiosDefaults.headers = withHeader.headers;
axiosDefaults.timeout = withHeader.timeOut;

export const sendMessageRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.SEND_MESSAGE}`,value)
        .then((response) => {
            if(response.data.responseCode===200)
            {
                dispatch(messageSuccessResult(response.data.responseBody,response.status));
            }
            else{
                dispatch(messageFailResult(response.data.responseMessage,response.data.responseCode));
            }
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2x
                dispatch(messageFailResult(error.response.message,error.response.status));
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        });
    }
};

/*
    API RESULT
 */


export const messageSuccessResult = (success,status) => ({
    type: MESSAGE_SUCCESS,
    success,
    status
});

export const messageFailResult = (error,status) => ({
    type: MESSAGE_FAIL,
    error,
    status
});
