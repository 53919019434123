import React, { Component, Fragment } from 'react';
import './JournalType.scss';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import * as routes from '../../../utils/RouteConstant';
import * as webConst from '../../../utils/WebConstant';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { Cube } from 'react-preloaders2';
import Table from 'react-bootstrap/Table';
import { OverlayTrigger, Overlay, Tooltip, Button, Popover, Modal, Tabs, Tab, Form, Card } from 'react-bootstrap';
import { getJournalTypeRequest, addJournalTypeRequest, updateJournalTypeRequest, deleteJournalTypeRequest } from '../../../containers/Journals/JournalTypeContainer/action';
import { JOURNAL_TYPE_SUCCESS, JOURNAL_TYPE_FAIL, ADD_JOURNAL_TYPE_SUCCESS, UPDATE_JOURNAL_TYPE_SUCCESS, DELETE_JOURNAL_TYPE_SUCCESS } from '../../../containers/Journals/JournalTypeContainer/Constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import daLocale from "date-fns/locale/da";
import EditIcon from '../../../images/edit-icon.svg';
import DeleteIcon from '../../../images/delete-icon.svg';
import { getTranslate } from 'react-localize-redux';

class JournalType extends Component {
	constructor(props) {
		super(props);
		this.state = {
			'isLoading': true,
			'journalTypes': [],
			'paged': 1,
			'isMoreRecord': true,
			'clientId': '',
			'journalType': '',
			'isJournalTypeLoading': true,
			'isFormLoading': false,
			'validated': false,
			'journalTypeForm': '',
			'pageSize': 20,
			'pageNumber': 0,
			'formErrors': [],
			'activeKey': 'view',
			'id': ''
		};

		this.journalTypeForm = React.createRef();
		this.getJounalType = this.getJounalType.bind(this);
		this.handleJournalType = this.handleJournalType.bind(this);
	}

	componentDidMount() {
		this.getJounalType();
	}

	componentDidUpdate(prevProps) {
		const { journalTypeSuccess, journalTypeFail, journalTypeStatus, journalType } = this.props;

		if (journalTypeStatus === 200) {
			if (prevProps.journalTypeSuccess !== journalTypeSuccess) {
				if (JOURNAL_TYPE_SUCCESS === journalType) {
					let total = this.props.journalTypeSuccess.total;
					this.setState({ journalTypes: [...this.state.journalTypes, ...journalTypeSuccess.journal_types] });
					this.setState({
						totalRecord: total,
						isMoreRecord: true,
						isLoading: false,
						isTimeSlotLoading: false,
						paged: parseInt(this.state.paged) + 1
					});
				}

				if (ADD_JOURNAL_TYPE_SUCCESS === journalType) {
					let journalTypes = this.state.journalTypes;
					journalTypes.unshift(journalTypeSuccess);
					this.handleClearForm();
					this.setState({ journalTypes: journalTypes, activeKey: "view" });
					webConst.toaster(webConst.TOAST_SUCCESS, <><Translate id="journal-type.journal-type-save-alert-message" /> </>);
				}

				if (UPDATE_JOURNAL_TYPE_SUCCESS === journalType) {
					let journalTypes = this.state.journalTypes.map(item => {
						return item.id === journalTypeSuccess.id ? journalTypeSuccess : item;
					});
					this.handleClearForm();
					this.setState({ journalTypes: journalTypes, activeKey: "view" });
					webConst.toaster(webConst.TOAST_SUCCESS, <><Translate id="journal-type.journal-type-update-alert-message" /> </>);
				}

				if (DELETE_JOURNAL_TYPE_SUCCESS === journalType) {
					let _this = this;
					webConst.toaster(webConst.TOAST_SUCCESS, <><Translate id="journal-type.journal-type-delete-alert-message" /> </>);
					setTimeout(() => {
						let journalTypes = [];
						for (var index in _this.state.journalTypes) {
							let item = _this.state.journalTypes[index];
							if (item.id != journalTypeSuccess.id) {
								journalTypes.push(item);
							}
						}
						this.setState({ journalTypes: [] });
						if (journalTypeSuccess.id) {
							this.setState({ journalTypes: journalTypes, activeKey: "view" });
						}
					}, 1000);
				}

				if (this.state.isFormLoading) {
					this.setState({
						isFormLoading: false,
						validated: false,
						journalType: "",
						id: "",
						activeKey: "view"
					});
				}
			}
		}
		else {
			if (this.state.isJournalTypeLoading) {
				this.setState({
					isLoading: false,
					isJournalTypeLoading: false,
					isMoreRecord: false
				});
				if (JOURNAL_TYPE_FAIL === journalType) {
					this.setState({
						isMoreRecord: false
					});
				}
			}

			if (this.state.isFormLoading) {
				this.setState({
					isFormLoading: false
				});

				if (journalTypeStatus == 422) {
					if (prevProps.journalTypeFail !== journalTypeFail) {
						webConst.toaster(webConst.TOAST_ERROR, <><Translate id="alert-message.form-validition" /></>);
						this.setState({ formErrors: journalTypeFail });
					}
				}
			}
		}
	}

	async getJounalType() {
		let pageNumber = this.state.pageNumber + 1;
		if ((this.state.totalRecord > this.state.journalTypes.length) || pageNumber == 1) {
			if (!this.state.isJournalTypeLoading) {
				this.setState({ 'isJournalTypeLoading': true, 'isLoading': true });
			}
			const params = new URLSearchParams();
			params.append('page', pageNumber);
			params.append('limit', this.state.pageSize);
			params.append('clinic_id', webConst.getClinicId());
			this.props.getJournalTypeRequest(params);
			this.setState({ pageNumber: pageNumber });
		}
	}


	handleJournalType = (event) => {
		event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			this.setState({ "validated": true });
			event.preventDefault();
			event.stopPropagation();
		}
		else {
			const params = new URLSearchParams();
			params.append('id', this.state.id);
			params.append('clinic_id', webConst.getClinicId());
			params.append('name', this.state.journalType);
			if (this.state.id > 0)
				this.props.updateJournalTypeRequest(this.state.id, params);
			else
				this.props.addJournalTypeRequest(params);
			this.setState({ "isFormLoading": true });
		}
		this.setState({ "validated": true });
	}

	handleClearForm = (event) => {
		this.journalTypeForm.current.reset();
		this.setState({ "journalTypes": "", "validated": false, formErrors: [], "id": "" });
		this.journalTypeForm.current.classList.remove("was-validated");
	}

	editRow = (type) => {
		this.setState({
			activeKey: "add",
			id: type.id,
			journalType: type.name
		});
	}

	deleteRow = (type) => {
		let _this = this;
		let yes = "", no = "", message = "";
		if (this.props.localize) {
			const translate = getTranslate(this.props.localize);
			yes = translate("general.yes");
			no = translate("general.no");
			message = translate("journal-type.delete-confirm-alert-message");
		}
		let options = {
			closeOnEscape: true,
			closeOnClickOutside: true,
			customUI: ({ onClose }) => {
				return (
					<Card className="pt-3 p-2">
						<Card.Body>
							<Card.Text className='pb-2'>
								<strong>{message}</strong>
							</Card.Text>
							<div className="d-flex flex-row bd-highlight mb-3">
								<div className="p-2 bd-highlight">
									<button className='validi-theme-btn align-baseline'
										onClick={() => {
											if (type.id) {
												_this.setState({ 'isLoading': true });
												_this.props.deleteJournalTypeRequest(type.id);
												onClose();
											}
										}}
									> {yes}</button>
								</div>
								<div className="p-2 bd-highlight"><button className='validi-theme-btn align-middle'
									onClick={() => {
										onClose();
									}}>{no}</button></div>
							</div>
						</Card.Body>
					</Card>
				);
			},
			overlayClassName: "overlay-custom"
		};
		confirmAlert(options);
	}

	render() {
		return (
			<div className="validi-main-layout validi-detox-medicine-layout">
				<div>
					<div className="container">
						<h1 className="main-title"><Translate id="journal-type.title" /></h1>
						<div className="validi-medicine-wrapper">
							<div className="validi-tabbar-wrapper">
								<Tabs id="controlled-tab-example" activeKey={this.state.activeKey} onSelect={(id) => { this.setState({ activeKey: id }) }}>
									<Tab eventKey="view" title={<Translate id="journal-type.title" />}>
										<div className="">
											<div className="validi-tabbar-inner-header">
												<div className="">
													<h4 className="validi-subtitle"><Translate id="journal-type.title" /></h4>
												</div>
												<div className="validi-table-filter-box">
													<form>
														<div className="form-group">
															<input type="search" className="form-control d-none" placeholder="Search…" />
														</div>
													</form>
												</div>
											</div>
											<div className="validi-table-data-wrapper">
												<InfiniteScroll
													dataLength={this.state.journalTypes && this.state.journalTypes.length ? this.state.journalTypes.length : 0}
													next={() => this.getJournalType}
													hasMore={this.state.isMoreRecord}
													loader={this.state.isJournalTypeLoading ? <h4><Translate id="general.loading" /></h4> : ""}
												>
													<Table responsive="md">
														<thead>
															<tr>
																<th>#</th>
																<th><Translate id="journal-type.journal-type" /></th>
																<th><Translate id="general.action" /></th>
															</tr>
														</thead>
														<tbody>
															{this.state.journalTypes.map((type, index) => (
																<tr key={index}>
																	<td className="validi-date-text">{index + 1}</td>
																	<td>{type.name}</td>
																	<td className='text-right'>
																		<div className="validi-active-icons-wrapper">
																			<i onClick={() => this.editRow(type)}><img src={EditIcon} alt="edit row" /></i>
																			<i onClick={() => this.deleteRow(type)}><img src={DeleteIcon} alt="delete row" /></i>
																		</div>
																	</td>
																</tr>
															))}
														</tbody>
													</Table>
												</InfiniteScroll>
											</div>
										</div>
									</Tab>
									<Tab eventKey="add" title={this.state.id ? <Translate id="journal-type.update-journal-type" /> : <Translate id="journal-type.add-journal-type" />}>
										<div className="validi-tabbar-inner-header">
											<h4 className="validi-subtitle">{this.state.id ? <Translate id="journal-type.update-journal-type" /> : <Translate id="journal-type.add-journal-type" />}</h4>
										</div>
										<div className="row">
											<div className="col-md-6">
												<Form noValidate validated={this.state.validated} onSubmit={this.handleJournalType} ref={this.journalTypeForm}>
													<Form.Group className="form-group" controlId="journalType">
														<Form.Label><Translate id="journal-type.journal-type" /></Form.Label>
														<Translate>
															{({ translate }) =>
																<Form.Control
																	type="tex"
																	name="journalType"
																	value={this.state.journalType ? this.state.journalType : ""}
																	onChange={(e) => { this.setState({ journalType: e.target.value }) }}
																	placeholder={translate("journal-type.journal-type")}
																	required
																/>
															}
														</Translate>
														{this.state.formErrors && this.state.formErrors.name ? <small className="text-danger">{this.state.formErrors.name}</small> : ""}
														<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
													</Form.Group>
													<div className="">
														<button className="validi-theme-btn">
															{webConst.isLoader(this.state.isFormLoading, <Translate id="general.save" />)}
														</button>
														<button type="button" className="validi-theme-btn clear-btn" onClick={this.handleClearForm}><Translate id="general.clear" /></button>
													</div>
												</Form>
											</div>
										</div>
									</Tab>
								</Tabs>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}


function mapStateToProps(state) {
	return {
		journalTypeSuccess: state.journalType.success,
		journalTypeFail: state.journalType.fail,
		journalTypeStatus: state.journalType.status,
		journalType: state.journalType.type,
		localize: state.localize
	};
}

const mapDispatchToProps = { getJournalTypeRequest, addJournalTypeRequest, updateJournalTypeRequest, deleteJournalTypeRequest };

export default connect(mapStateToProps, mapDispatchToProps)(JournalType);

