import { LOGOUT_SUCCESS, LOGOUT_FAIL } from './Constants';
import * as webConst from '../../../utils/WebConstant';
import { withHeader } from '../../../service/ApiConfig';
import axios from 'axios';
const axiosDefaults = require('axios/lib/defaults');
axiosDefaults.baseURL = withHeader.baseURL;
axiosDefaults.headers = withHeader.headers;
axiosDefaults.timeout = withHeader.timeOut;

/*
    API REQUEST 
 */

export const logoutRequest = () => {
    return async dispatch => {
        const params = new URLSearchParams();
        axios.post(`${webConst.LOGOUT}`, params)
            .then((response) => {
                if (response.data.status === 200) {
                    webConst.emitter.emit(webConst.LOGIN_EVENT);
                    webConst.removeUserToken();
                    dispatch(logoutSuccessResult(response.data.data, response.status));
                }
                else {
                    dispatch(logoutFailResult(response.data.message, response.data.responseCode));
                }
            })
            .catch((error) => {
                dispatch(logoutFailResult(error.response.data.message,error.response.status));
            });
    }

}

/*
    API RESULT
 */

export const logoutSuccessResult = (success, status) => ({
    type: LOGOUT_SUCCESS,
    success,
    status
});


export const logoutFailResult = (error, status) => ({
    type: LOGOUT_FAIL,
    error,
    status
});
