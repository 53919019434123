import {
    DETOX_PLAN_SUCCESS,
    DETOX_PLAN_FAIL,
    DETOX_PLAN_DETAIL_SUCCESS,
    DETOX_PLAN_DETAIL_FAIL,
    UPDATE_DETOX_DOSAGE_SUCCESS,
    UPDATE_DETOX_DOSAGE_DETAIL_FAIL,
    GET_DISPENSE_DOSAGE_SUCCESS,
    GET_DISPENSE_DOSAGE_FAIL,
    DISPENSE_DOSAGE_STATUS_SUCCESS,
    DISPENSE_DOSAGE_STATUS_FAIL,
    DETOX_DISPENSE_HISTORY_SUCCESS,
    DETOX_DISPENSE_HISTORY_FAIL,
    DETOX_SUSPENDED_HISTORY_SUCCESS,
    DETOX_SUSPENDED_HISTORY_FAIL,
    DETOX_SUSPEND_SUCCESS,
    DETOX_SUSPEND_FAIL,
    DETOX_ACTIVE_TAB,
    DETOX_ID,
    ADD_DETOX_PLAN_SUCCESS,
    ADD_DETOX_PLAN_FAIL,
    UPDATE_DETOX_PLAN_SUCCESS,
    UPDATE_DETOX_PLAN_FAIL
} from './Constants';

import axios from 'axios';
import { withHeader } from '../../../service/ApiConfig';
import * as webConst from '../../../utils/WebConstant';
const axiosDefaults = require('axios/lib/defaults');
axiosDefaults.baseURL = withHeader.baseURL;
axiosDefaults.headers = withHeader.headers;
axiosDefaults.timeout = withHeader.timeOut;

//get active detox history
export const getDetoxPlanRequest = (value) => {
    return dispatch => {
        axios.get(`${webConst.DETOX}?`+value)
            .then((response) => {
                if(response.status===200){
                    dispatch(detoxPlanSuccessResult(response.data.data, response.status));
                }
                else {
                    dispatch(detoxPlanFailResult(response.data.data.message, response.status));
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    dispatch(detoxPlanFailResult(error.response.message,error.response.status));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            }); 
    }
};

//get detox detail by detox id
export const getDetoxPlanDetailRequest = (value) => {
    return dispatch => {
        axios.get(`${webConst.DETOX}/`+value)
            .then((response) => {
                if(response.status===200){
                    dispatch(detoxPlanDetailSuccessResult(response.data.data, response.status));
                }
                else {
                    dispatch(detoxPlanDetailFailResult(response.data.message, response.status));
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    dispatch(detoxPlanDetailFailResult(error.response.message,error.response.status));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            }); 
    }
};

//update detox dosage amount
export const updateDetoxDosageRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.UPDATE_DETOX_DOSAGE}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    dispatch(detoxDosageSuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(detoxDosageFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                dispatch(detoxDosageFailResult(error.data, error.status));
            });
    }
};

//get detox dispense dosage events
export const getDispenseDetoxDosageRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.GET_DISPENSE_DETOX_DOSAGE_EVENT}`, value)
            .then((response) => {
                if(response.status===200){
                    dispatch(dispenseDetoxDosageSuccessResult(response.data.data, response.status));
                }
                else {
                    dispatch(dispenseDetoxDosageFailResult(response.message, response.status));
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    dispatch(detoxPlanDetailFailResult(error.response.message,error.response.status));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            }); 
    }
};

//update detox dispense status
export const dispenseDosageStatusRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.DETOX_DISPENSE_DOSAGE}`, value)
            .then((response) => {
                if(response.status===200){
                    dispatch(dispenseDosageStatusSuccessResult(response.data.data, response.status));
                }
                else {
                    dispatch(dispenseDosageStatusFailResult(response.data.message, response.status));
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    dispatch(detoxPlanDetailFailResult(error.response.message,error.response.status));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            }); 
    }
};

//get detox dispense history
export const getDetoxDispenseHistoryRequest = (value) => {
    return dispatch => {
        axios.get(`${webConst.DETOX_DISPENSE_HISTORY}?`+value)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(detoxDispenseHistorySuccessResult(response.data.data, response.status));
                }
                else {
                    dispatch(detoxDispenseHistoryFailResult(response.data.message, response.status));
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    dispatch(detoxDispenseHistoryFailResult(error.response.message,error.response.status));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    }
};

//get detox suspended history
export const detoxSuspendedHistoryRequest = (value) => {
    return dispatch => {
        axios.get(`${webConst.DETOX_SUSPENDED_HISTORY}?`+ value)
            .then((response) => {
                if(response.status===200){
                    // console.log("response",response.data);
                    dispatch(detoxSuspendedHistorySuccessResult(response.data.data, response.status));
                }
                else {
                    dispatch(detoxSuspendedHistoryFailResult(response.data.message, response.status));
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    dispatch(detoxSuspendedHistoryFailResult(error.response.message,error.response.status));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            }); 
    }
};

//get detox suspended history
export const detoxSuspendRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.DETOX_SUSPEND}`, value)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(detoxSuspendedSuccessResult(response.data.data, response.status));
                }
                else {
                    dispatch(detoxSuspendedFailResult(response.data.message, response.status));
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    dispatch(detoxSuspendedFailResult(error.response.message,error.response.status));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            }); 
    }
};

//add detox
export const addDetoxPlanRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.DETOX}`, value)
            .then((response) => {
                if(response.status===200)
                {
                    dispatch(addDetoxSuccessResult(response.data.data,response.status));
                }
                else{
                    dispatch(addDetoxFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    dispatch(addDetoxFailResult(error.response.message,error.response.status));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            }); 
    }
};


//update detox
export const updateDetoxPlanRequest = (value,detoxId) => {
    return dispatch => {
        axios.put(`${webConst.DETOX}/`+detoxId, value)
            .then((response) => {
                if(response.status===200)
                {
                    dispatch(updateDetoxSuccessResult(response.data.data,response.status));
                }
                else{
                    dispatch(updateDetoxFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    dispatch(updateDetoxFailResult(error.response.message,error.response.status));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            }); 
    }
};

/*
    API RESULT
 */

export const detoxPlanSuccessResult = (success, status) => ({
    type: DETOX_PLAN_SUCCESS,
    success,
    status
});

export const detoxPlanFailResult = (error, status) => ({
    type: DETOX_PLAN_FAIL,
    error,
    status
});

export const detoxPlanDetailSuccessResult = (success, status) => ({
    type: DETOX_PLAN_DETAIL_SUCCESS,
    success,
    status
});

export const detoxPlanDetailFailResult = (error, status) => ({
    type: DETOX_PLAN_DETAIL_FAIL,
    error,
    status
});

export const detoxDosageSuccessResult = (success, status) => ({
    type: UPDATE_DETOX_DOSAGE_SUCCESS,
    success,
    status
});

export const detoxDosageFailResult = (error, status) => ({
    type: UPDATE_DETOX_DOSAGE_DETAIL_FAIL,
    error,
    status
});

export const dispenseDetoxDosageSuccessResult = (success, status) => ({
    type: GET_DISPENSE_DOSAGE_SUCCESS,
    success,
    status
});

export const dispenseDetoxDosageFailResult = (error, status) => ({
    type: GET_DISPENSE_DOSAGE_FAIL,
    error,
    status
});

export const dispenseDosageStatusSuccessResult = (success, status) => ({
    type: DISPENSE_DOSAGE_STATUS_SUCCESS,
    success,
    status
});

export const dispenseDosageStatusFailResult = (error, status) => ({
    type: DISPENSE_DOSAGE_STATUS_FAIL,
    error,
    status
});

export const detoxDispenseHistorySuccessResult = (success, status) => ({
    type: DETOX_DISPENSE_HISTORY_SUCCESS,
    success,
    status
});

export const detoxDispenseHistoryFailResult = (error, status) => ({
    type: DETOX_DISPENSE_HISTORY_FAIL,
    error,
    status
});

export const detoxSuspendedHistorySuccessResult = (success, status) => ({
    type: DETOX_SUSPENDED_HISTORY_SUCCESS,
    success,
    status
});

export const detoxSuspendedHistoryFailResult = (error, status) => ({
    type: DETOX_SUSPENDED_HISTORY_FAIL,
    error,
    status
});

export const detoxSuspendedSuccessResult = (success, status) => ({
    type: DETOX_SUSPEND_SUCCESS,
    success,
    status
});

export const detoxSuspendedFailResult = (error, status) => ({
    type: DETOX_SUSPEND_FAIL,
    error,
    status
});

export const setDetoxActiveTab = (detoxType) => ({
    type: DETOX_ACTIVE_TAB,
    detoxType
});

export const setDetoxId = (detoxId) => ({
    type: DETOX_ID,
    detoxId
});

export const addDetoxSuccessResult = (success, status) => ({
    type: ADD_DETOX_PLAN_SUCCESS,
    success,
    status
});

export const addDetoxFailResult = (error, status) => ({
    type: ADD_DETOX_PLAN_FAIL,
    error,
    status
});

export const updateDetoxSuccessResult = (success, status) => ({
    type: UPDATE_DETOX_PLAN_SUCCESS,
    success,
    status
});

export const updateDetoxFailResult = (error, status) => ({
    type: UPDATE_DETOX_PLAN_FAIL,
    error,
    status
});
