export const PERSONAL_JOURNAL_SUCCESS = 'PERSONAL_JOURNAL_SUCCESS';
export const PERSONAL_JOURNAL_FAIL = 'PERSONAL_JOURNAL_FAIL';

export const ADD_PERSONAL_JOURNAL_SUCCESS = 'ADD_PERSONAL_JOURNAL_SUCCESS';
export const ADD_PERSONAL_JOURNAL_FAIL = 'ADD_PERSONAL_JOURNAL_FAIL';

export const UPDATE_PERSONAL_JOURNAL_SUCCESS = 'UPDATE_PERSONAL_JOURNAL_SUCCESS';
export const UPDATE_PERSONAL_JOURNAL_FAIL = 'UPDATE_PERSONAL_JOURNAL_FAIL';

export const DELETE_PERSONAL_JOURNAL_SUCCESS = 'DELETE_PERSONAL_JOURNAL_SUCCESS';
export const DELETE_PERSONAL_JOURNAL_FAIL = 'DELETE_PERSONAL_JOURNAL_FAIL';