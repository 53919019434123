import { PERSONAL_JOURNAL_SUCCESS, PERSONAL_JOURNAL_FAIL, ADD_PERSONAL_JOURNAL_SUCCESS, ADD_PERSONAL_JOURNAL_FAIL, UPDATE_PERSONAL_JOURNAL_SUCCESS, UPDATE_PERSONAL_JOURNAL_FAIL, DELETE_PERSONAL_JOURNAL_SUCCESS, DELETE_PERSONAL_JOURNAL_FAIL } from './Constants';

const initialState = {
  isLoading: true
};
export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case PERSONAL_JOURNAL_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type: action.type
      };
    case PERSONAL_JOURNAL_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case ADD_PERSONAL_JOURNAL_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type: action.type
      };
    case ADD_PERSONAL_JOURNAL_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type: action.type
      };
    case UPDATE_PERSONAL_JOURNAL_SUCCESS:
        return {
          ...state,
          success: action.success,
          fail: '',
          status: action.status,
          type: action.type
        };
    case UPDATE_PERSONAL_JOURNAL_FAIL:
        return {
          ...state,
          success: '',
          fail: action.error,
          status: action.status,
          type: action.type
        };
    case DELETE_PERSONAL_JOURNAL_SUCCESS:
          return {
            ...state,
            success: action.success,
            fail: '',
            status: action.status,
            type: action.type
          };
    case DELETE_PERSONAL_JOURNAL_FAIL:
          return {
            ...state,
            success: '',
            fail: action.error,
            status: action.status,
            type: action.type
          };
    default:
      return state;
  }
}