import React, { Component } from 'react';
import { Tabs, Tab, Table, FormGroup, Label, Form, FormControl, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './AddPN.scss';
import * as routes from '../../../../utils/RouteConstant';
import backArrow from '../../../../images/back-arrow.svg';
import { Translate } from 'react-localize-redux';
import * as webConst from '../../../../utils/WebConstant';
import { getMedicinePlanDetailRequest, getMedicineTypeRequest, getMedicineStrengthRequest } from '../../../../containers/Medicines/MedicinePlanDetailContainer/action';
import { GET_MEDICINE_PLAN_DETAIL_SUCCESS, MEDICINE_STRENGTH_SUCCESS, MEDICINE_TYPE_SUCCESS } from '../../../../containers/Medicines/MedicinePlanDetailContainer/Constants';
import { addPnMedicinePlanRequest, updatePnMedicinePlanRequest, setPnActiveTab } from '../../../../containers/Medicines/Pn/PnPlanContainer/action';
import { ADD_PN_PLAN_SUCCESS, ADD_PN_PLAN_FAIL, PN_EDIT, UPDATE_PN_PLAN_SUCCESS, UPDATE_PN_PLAN_FAIL } from '../../../../containers/Medicines/Pn/PnPlanContainer/Constants';
import { getMedicineRequest } from '../../../../containers/Medicines/MedicineContainer/action';
import { MEDICINE_SUCCESS } from '../../../../containers/Medicines/MedicineContainer/Constants';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import daLocale from "date-fns/locale/da";
import moment from 'moment';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import 'react-bootstrap-typeahead/css/Typeahead.css';

class AddPN extends Component {
	constructor(props) {
		super(props);
		this.state = {
			clientId: "",
			pnId: "",
			isLoading: false,
			validated: false,
			addPnForm: "",
			medicineId: "",
			totalStrength: 0,
			dosageAmount: 0,
			isFormLoading: false,
			isPnLoading: false,
			medicines: [],
			medicineTypes: [],
			medicineStrengths: [],
			startDate: "",
			medicineType: "Tablet",
			packageId: "",
			medicineTypeId: "",
			doctorType: 2,
			internalDoctorId: "",
			externalDoctorId: "",
			internalDoctors: [],
			externalDoctors: [],
			isMedicineDetailLoaded: 0,
			medicineOptions: [],
			optionMedicineStrengthes: [],
			optionMedicineTypes: [],
			isOpenMedicineTypehead: false,
			isOpenTypehead: false,
			isOpenStrengthTypehead: false,
			isMedicineLoading: false,
			isMedicineTypeLoading: false,
			isMedicineStrengthLoading: false,
			selectedMedicine: {},
			selectedMedicineType: {},
			selectedMedicineStrength: {},
			medicineError: "",
			medicineTypeError: "",
			medicineStrengthError: ""
		};
		this.addPnForm = React.createRef();
	}

	componentDidMount() {
		let _this = this;
		let clientId = webConst.getClientId();
		if (clientId > 0) {
			this.setState({ clientId: clientId, isMedicineDetailLoaded: 0 });
		}
		else {
			webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.select-client-message" />);
			this.props.history.push(routes.HOME_ROUTE);
		}
		this.setState({ "clientId": webConst.getClientId() });
		setTimeout(() => {
			// _this.getMedicinePlanDetail();
		}, 100);
	}

	componentDidUpdate(prevProps) {
		let _this = this;
		const { medicinePlanDetailSuccess, medicinePlanDetailStatus, medicinePlanDetailType, medicineSuccess, medicineStatus, medicineType, pnPlanSuccess, pnPlanStatus, pnPlanType, pnActiveTab, editPn } = this.props;
		if (pnActiveTab === 'add-pn' && this.state.isMedicineDetailLoaded === 0) {
			let params = new URLSearchParams();
			params.append('clinic_id', webConst.getClinicId());
			params.append('client_id', this.state.clientId);
			params.append('pn_id', _this.state.pnId);
			params.append('medicine_id', _this.state.medicineId);
			_this.props.getMedicinePlanDetailRequest(params);
			_this.setState({ isMedicineDetailLoaded: 1 });
		}

		if (prevProps.editPn !== editPn) {
			if (editPn != "") {
				if (editPn.id != this.state.pnId)
					this.setState({ pnId: editPn.id });
				if (editPn.doctor_type != this.state.doctorType)
					this.setState({ doctorType: editPn.doctor_type });
				if (editPn.doctor_id != this.state.internalDoctorId)
					this.setState({ internalDoctorId: editPn.doctor_id });
				if (editPn.doctor_id != this.state.externalDoctorId)
					this.setState({ externalDoctorId: editPn.doctor_id });
				if (editPn.medicine_id != this.state.selectedMedicine) {
					var medicine = [{ "value": editPn.medicine_id, "name": editPn.medicine.name }];
					this.handleSearch(editPn.medicine.name);
					this.setState({ selectedMedicine: medicine });
					this.handleChangeMedicine(medicine);
					setTimeout(() => {
						this.setState({ medicineId: editPn.medicine_id, isOpenTypehead: false });
						let medicineType = [{ "value": editPn.medicine_type.id, "name": editPn.medicine_type.medicine_type }];
						_this.handleChangeMedicineType(medicineType);
					}, 100);

					setTimeout(() => {
						let medicineStrength = [{ "value": editPn.package_id, "name": editPn.medicine_package.unit }];
						_this.handleChangeMedicineStrength(medicineStrength);
						_this.setState({ packageId: editPn.medicine_package.id, isOpenStrengthTypehead: false });
					}, 500);
				}
				if (editPn.initial_amount != this.state.initialDosage)
					this.setState({ initialDosage: editPn.initial_amount });
				if (editPn.start_date != this.state.startDate)
					this.setState({ startDate: moment(editPn.start_date, "YYYY-MM-DD").toDate() });
				if (editPn.note != this.state.note)
					this.setState({ note: editPn.note });
				if (editPn.effect != this.state.medicineEffect)
					this.setState({ medicineEffect: editPn.effect });				// /// 	this.setState({selectedMedicineType:editPn.package_id});
				if (editPn.package_id != this.state.selectedMedicineStrength)
					this.setState({ packageId: editPn.package_id });
				setTimeout(() => {
					_this.setState({ isOpenMedicineTypehead: false, isOpenStrengthTypehead: false, isOpenTypehead: false });
				}, 500);
			}
		}

		if (medicineStatus === 200) {
			if (prevProps.medicineSuccess !== medicineSuccess) {
				_this.setState({ medicines: medicineSuccess });
				if (this.state.isMedicineLoading) {
					this.setState({
						isLoading: false,
						isMedicineLoading: false,
					});
				}
			}
		}
		if (medicineStatus === 200) {
			if (medicineType === MEDICINE_SUCCESS && prevProps.medicineSuccess !== medicineSuccess) {
				_this.setState({ medicines: medicineSuccess });

				if (this.state.isMedicineLoading) {
					this.setState({
						isLoading: false,
						isMedicineLoading: false,
					});
				}
			}
		}
		else {
			if (this.state.isMedicineLoading) {
				this.setState({
					isLoading: false,
					isMedicineLoading: false,
				});
			}
		}

		if (medicinePlanDetailStatus === 200) {
			if (prevProps.medicinePlanDetailSuccess !== medicinePlanDetailSuccess) {
				this.setState({
					isLoading: false,
					isFormLoading: false,
					isUpdateMedicineType: false,
					isMedicineDetailLoaded: true
				});
				//console.log("medicinePlanDetailSuccess",medicinePlanDetailSuccess);
				this.setInternalDoctors(medicinePlanDetailSuccess);
				this.setExternalDoctors(medicinePlanDetailSuccess);
				this.setTimeSlots(medicinePlanDetailSuccess);

				if (medicinePlanDetailSuccess.medicine_types && medicinePlanDetailSuccess.medicine_types.length) {
					let medicine_types = [];
					for (var index in medicinePlanDetailSuccess.medicine_types) {
						medicine_types.push({ value: medicinePlanDetailSuccess.medicine_types[index].id, name: webConst.ucfirst(medicinePlanDetailSuccess.medicine_types[index].medicine_type) });
					}
					this.setState({ medicineTypes: medicinePlanDetailSuccess.medicine_types, optionMedicineTypes: medicine_types, isMedicineTypeLoading: false, medicineTypeError: "", medicineStrengthError: "" });
				}
				if (medicinePlanDetailSuccess.medicine_strengths && medicinePlanDetailSuccess.medicine_strengths.length) {
					let medicineStrengthes = [];
					for (var index in medicinePlanDetailSuccess.medicine_strengths) {
						medicineStrengthes.push({ value: medicinePlanDetailSuccess.medicine_strengths[index].id, name: webConst.ucfirst(medicinePlanDetailSuccess.medicine_strengths[index].unit) });
					}
					this.setState({ medicineStrengthes: medicinePlanDetailSuccess.medicine_strengths, optionMedicineStrengthes: medicineStrengthes, isMedicineStrengthLoading: false, medicineStrengthError: "" });
				}
			}
			if (medicinePlanDetailType === MEDICINE_TYPE_SUCCESS && prevProps.medicinePlanDetailSuccess !== medicinePlanDetailSuccess) {
				if (Object.values(medicinePlanDetailSuccess.medicine_types).length) {
					let medicine_types = [];
					for (var index in medicinePlanDetailSuccess.medicine_types) {
						medicine_types.push({ value: index, name: webConst.ucfirst(medicinePlanDetailSuccess.medicine_types[index]) });
					}
					this.setState({ medicineTypes: medicinePlanDetailSuccess.medicine_types, optionMedicineTypes: medicine_types });
				}
				if (this.state.isMedicineTypeLoading) {
					this.setState({
						isLoading: false,
						isMedicineTypeLoading: false
					});
				}
			}
		}

		if (medicinePlanDetailStatus === 400) {
			if (this.state.isLoading) {
				this.setState({
					isLoading: false,
					isFormLoading: false
				});
			}
		}

		if (pnPlanStatus === 200) {
			if (pnPlanType === ADD_PN_PLAN_SUCCESS) {
				if (prevProps.pnPlanSuccess !== pnPlanSuccess) {
					if (this.state.isFormLoading) {
						this.setState({
							isFormLoading: false
						});
						this.resetForm();
						webConst.toaster(webConst.TOAST_SUCCESS, <Translate id="medicine.pn-save-message" />);
					}
				}
			}


			if (pnPlanType === UPDATE_PN_PLAN_SUCCESS) {
				if (prevProps.pnPlanSuccess !== pnPlanSuccess) {
					if (this.state.isFormLoading) {
						this.setState({
							isFormLoading: false
						});
						this.resetForm();
						webConst.toaster(webConst.TOAST_SUCCESS, <Translate id="medicine.pn-save-message" />);
						// this.props.history.push(routes.PN_PLAN_ROUTE);
						this.activeTab("active-pn");
					}
				}
			}
		}
	}

	handleChange = (event) => {
		let _this = this;
		let name = event.target.name;
		let value = event.target.value;
		console.log(name, value);
		this.setState({ [name]: value });
		console.log('clinic_id', webConst.getClinicId());

	}

	medicineFilter = (query) => {
		var medicines = [];
		let _this = this;
		setTimeout(() => {
			medicines = _this.state.medicines.filter((val) => {
				if (query == "") {
					return val
				}
				else if (val.name.toLowerCase().includes(query.toLowerCase())) {
					return val;
				}
			}).map((val, key) => {
				return val;
			});
		}, 100);
		return medicines;
	}

	handleChangeMedicine = (medicine) => {
		if (medicine.length) {
			let isOpenMedicineTypehead = (this.state.isOpenMedicineTypehead === true ? false : true);
			this.getMedicineType(medicine[0].value);
			this.setState({ medicineType: "Tabletter", isMedicineTypeLoading: true, isOpenMedicineTypehead: isOpenMedicineTypehead, medicineError: false });
			if (this.state.medicineId !== medicine[0].value)
				this.setState({ medicineId: medicine[0].value, medicineTypeId: "", packageId: "", selectedMedicine: medicine, selectedMedicineType: [], selectedMedicineStrength: [], medicineTypeError: "", medicineStrengthError: "" });
		} else {
			this.setState({ medicineId: "", medicineTypeId: "", packageId: "", selectedMedicine: [], selectedMedicineType: [], selectedMedicineStrength: [], medicineError: true, medicineTypeError: "", medicineStrengthError: "" });
		}
	}

	handleChangeMedicineType = (type) => {
		let _this = this;
		let isOpenTypehead = this.state.isOpenTypehead === true ? false : true;
		if (type.length) {
			this.getMedicineStrength(type[0].value);

			Object.keys(this.state.medicineTypes).filter((index) => {
				if (index == type[0].value) {
					_this.setState({ medicineType: webConst.ucfirst(_this.state.medicineTypes[index]) });
				}
			});

			this.setState({
				medicineTypeId: type[0].value,
				isOpenTypehead: isOpenTypehead,
				medicinePhoto: "",
				medicineUnit: "mg",
				medicineStrength: 0,
				selectedMedicineType: type,
				isMedicineStrengthLoading: true,
				selectedMedicineStrength: [],
				packageId: "",
			});
		} else {
			this.setState({ medicineTypeId: "", packageId: "", selectedMedicineType: [], selectedMedicineStrength: [] });
		}
	}

	handleChangeMedicineStrength = (strength) => {
		let _this = this;
		if (strength.length) {
			let value = strength[0].value;
			if (this.state.medicineStrengthes) {
				this.state.medicineStrengthes.filter((data) => {
					if (data.id == value) {
						_this.setState({ medicinePhoto: data.image, medicineUnit: data.unit, medicineStrength: data.unit_strength });
					}
				});
			}
			let packageId = strength[0].value;
			let isOpenStrengthTypehead = this.state.isOpenStrengthTypehead === true ? false : true;
			this.setState({ isOpenStrengthTypehead: isOpenStrengthTypehead, packageId: packageId, selectedMedicineStrength: strength, medicineStrengthError: false });
			this.totalStrength();
		} else {
			this.setState({ packageId: "", selectedMedicineStrength: [], medicineStrengthError: false });
		}
	}

	getMedicineType = (medicineId) => {
		this.setState({ 'isLoader': true });
		const params = new URLSearchParams();
		params.append('medicine_id', medicineId);
		this.props.getMedicineTypeRequest(params);
	}

	getMedicineStrength = (medicineTypeId) => {
		let medicineId = this.state.medicineId;
		if (medicineId > 0 && medicineTypeId > 0) {
			this.setState({ 'isLoader': true });
			const params = new URLSearchParams();
			params.append('medicine_id', medicineId);
			params.append('medicine_type_id', medicineTypeId);
			this.props.getMedicineStrengthRequest(params);
		}
	}

	totalStrength = () => {
		let total = 0;
		let initialDosage = this.state.initialDosage ? this.state.initialDosage : 0;
		let medicineStrength = this.state.medicineStrength ? this.state.medicineStrength : 0;
		let totalStrength = medicineStrength * initialDosage;
		this.setState({ totalStrength: totalStrength });
	}

	setInternalDoctors(data) {
		if (data.doctors && Object.values(data.doctors).length) {
			this.setState({ internalDoctors: data.doctors });
		}
	}

	setExternalDoctors(data) {
		if (data.external_doctors && Object.values(data.external_doctors).length) {
			this.setState({ externalDoctors: data.external_doctors });
		}
	}

	handleSearch = (query) => {
		if (this.state.isMedicineLoading === false) {
			this.setState({ isMedicineLoading: true });
			this.props.getMedicineRequest(query);
		}
	}

	setTimeSlots(medicinePlanDetailSuccess) {
		let _this = this;
		if (medicinePlanDetailSuccess.timeslots && Object.values(medicinePlanDetailSuccess.timeslots).length) {
			let timeSlots = medicinePlanDetailSuccess.timeslots;
			let selectedSlots = {};
			let slotDosages = {};
			// let sortedTime=this.timeSort(medicinePlanDetailSuccess.timeslots);
			let sortTimeSlots = {};
			timeSlots.forEach(slot => {
				let slot_id = slot.id;
				if (!selectedSlots[slot_id])
					selectedSlots[slot_id] = false;
				if (!slotDosages[slot_id])
					slotDosages[slot_id] = 0;
				if (!sortTimeSlots[slot_id])
					sortTimeSlots[slot_id] = "";
			});
			setTimeout(() => {
				_this.setState({ selectedSlots: selectedSlots, slotDosages: slotDosages, slotTimes: timeSlots });
			}, 100);
		}
	}

	setDate = (name, value) => {
		this.setState({ [name]: value });
	}

	addPnPlan = (event) => {
		event.preventDefault();
		const form = event.currentTarget;
		this.setState({ "validated": true });
		if (this.state.medicineId > 0)
			this.setState({ medicineError: true });
		else
			this.setState({ medicineError: false });

		if (this.state.medicineTypeId > 0)
			this.setState({ medicineTypeError: true });
		else
			this.setState({ medicineTypeError: false });

		if (this.state.packageId > 0)
			this.setState({ medicineStrengthError: true });
		else
			this.setState({ medicineStrengthError: false });

		if (form.checkValidity() === false) {
			this.setState({ "validated": false });
			event.preventDefault();
			event.stopPropagation();
			// this.addPnForm.current.classList.add("was-validated");
		}
		else {
			const params = new URLSearchParams();
			params.append('user_id', webConst.getUserId());
			params.append('clinic_id', webConst.getClinicId());
			params.append('client_id', webConst.getClientId());
			params.append('medicine_id', this.state.medicineId);
			params.append('initial_amount', this.state.initialDosage);
			params.append('start_date', moment(this.state.startDate).format("YYYY-MM-DD"));
			params.append('note', this.state.note ? this.state.note : "");
			params.append('effect', this.state.medicineEffect ? this.state.medicineEffect : "");
			params.append('medicine_type_id', this.state.medicineTypeId);
			params.append('package_id', this.state.packageId);
			params.append('doctor_type', this.state.doctorType);
			params.append('doctor_id', this.state.doctorType == "2" ? this.state.internalDoctorId : this.state.externalDoctorId);
			// this.props.addPnMedicinePlanRequest(params);
			// this.setState({ isFormLoading: true });
			if (this.state.pnId)
				this.props.updatePnMedicinePlanRequest(this.state.pnId, params);
			else
				this.props.addPnMedicinePlanRequest(params);
			this.setState({ isFormLoading: true });
		}
		this.setState({ "validated": true });
		event.preventDefault();
	}

	resetForm = () => {
		this.addPnForm.current.reset();
		this.setState({
			"pnId": "",
			"doctorType": "",
			"internalDoctorId": "",
			"externalDoctorId": "",
			"medicineId": "",
			"packageId": "",
			"initialDosage": "",
			"startDate": "",
			"note": "",
			"medicineEffect": "",
			"isOpenTypehead": false,
			"isOpenStrengthTypehead": false,
			"isOpenMedicineTypehead": false,
			"validated": true,
			"selectedMedicineType": {},
			"selectedMedicineStrength": {},
			"selectedMedicine": {},
			"medicineTypeError": false,
			"medicineError": false
		});
		this.setState({ "validated": false });
		this.addPnForm.current.classList.remove("was-validated");
	}

	activeTab = (id) => {
		this.props.setPnActiveTab(id);
	}

	render() {
		let _this = this;
		let total = 0;
		let initialDosage = this.state.initialDosage ? this.state.initialDosage : 0;
		let medicineStrength = this.state.medicineStrength ? this.state.medicineStrength : 0;
		let totalStrength = medicineStrength * initialDosage;
		let medicineUnit = this.state.medicineUnit ? this.state.medicineUnit : "";
		let unit = medicineUnit.replace(/[^a-zA-Z\/]+/g, '');
		unit = unit ? unit : "mg";
		if (this.state.totalStrength && totalStrength != this.state.totalStrength)
			this.setState({ totalStrength: totalStrength });
		let totalMg = initialDosage + " " + webConst.shortType(webConst.ucfirst(this.state.medicineType)) + " * " + medicineStrength + " " + unit + " = " + totalStrength + " " + unit;
		return (
			<div className="">
				<div className="validi-tabbar-inner-header">
					<div className="">
						<h4 className="validi-subtitle"><Translate id="medicine.add-pn-plan" /></h4>
					</div>
				</div>
				<div className="row">
					<div className="col-md-8 col-lg-6">
						<Form className="row" noValidate validated={this.state.validated} onSubmit={this.addPnPlan} ref={this.addPnForm}>
							<div className="col-md-6">
								<Form.Group className="form-group" controlId="interalDoctorId">
									<div className="validi-radio-btnbox">
										<Form.Check type={"radio"} id={`internal-doctor`} className="radio-btn">
											<Form.Check.Input type="radio" name="doctorType" value={2} onChange={(e) => { this.handleChange(e) }} checked={this.state.doctorType == 2} />
											<span className="checkmark"></span>
											<Form.Check.Label><Translate id="general.select-internal-doctor" /></Form.Check.Label>
										</Form.Check>
									</div>
									{this.state.doctorType && this.state.doctorType == 2 ?
										<div>
											<Form.Select
												type="select"
												name="internalDoctorId"
												value={this.state.internalDoctorId ? this.state.internalDoctorId : ""}
												onChange={(e) => { this.handleChange(e) }}
												className="mt-2"
												required
												placeholder="Select doctor"
											>
												<Translate>
													{({ translate }) =>
														<option key="internal-doctor-0" value="">{translate("general.select-internal-doctor")}</option>
													}
												</Translate>
												{this.state.internalDoctors ?
													Object.keys(this.state.internalDoctors).map(function (id) {
														return <option value={id} key={"internal-doctor-" + id}>{_this.state.internalDoctors[id]}</option>
													})
													: ""
												}
											</Form.Select>
											<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
										</div>
										: ""
									}
								</Form.Group>
							</div>
							<div className="col-md-6">
								<Form.Group className="form-group" controlId="externalDoctorId">
									<div className="validi-radio-btnbox mb-2">
										<Form.Check type={"radio"} id={`external-doctor`} className="radio-btn">
											<Form.Check.Input type="radio" name="doctorType" value="3" onChange={(e) => { this.handleChange(e) }} checked={this.state.doctorType == 3} />
											<span className="checkmark"></span>
											<Form.Check.Label><Translate id="general.select-external-doctor" /></Form.Check.Label>
										</Form.Check>
									</div>
									{this.state.doctorType && this.state.doctorType == 3 ?
										<div>
											<Form.Select
												type="select"
												name="externalDoctorId"
												value={this.state.externalDoctorId ? this.state.externalDoctorId : ""}
												onChange={(e) => { this.handleChange(e) }}
												className="mt-2"
												placeholder="Select doctor"
												required
											>
												<Translate>
													{({ translate }) =>
														<option key="external-doctor-0" value="">{translate("general.select-external-doctor")}</option>
													}
												</Translate>
												{this.state.externalDoctors ?
													Object.keys(this.state.externalDoctors).map(function (id) {
														return <option key={"external-doctor-" + id} value={id}>
															{_this.state.externalDoctors[id]}
														</option>
													})
													: ""
												}
											</Form.Select>
											<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
										</div>
										: ""
									}
								</Form.Group>
							</div>
							<div className="col-md-12">
								<div className="form-group">
									<Form.Group className="form-group" controlId="medicineId">
										<Form.Label><Translate id="medicine.medicine" /></Form.Label>
										{/* - {this.state.medicineError ? "is-invalid" : "is-valid"} */}
										<AsyncTypeahead
											id="medicineId"
											labelKey="name"
											className={this.state.medicineError ? "is-invalid" : "is-valid"}
											onSearch={(e) => this.handleSearch(e)}
											options={this.state.medicines && this.state.medicines.length ? this.state.medicines : []}
											isLoading={this.state.isMedicineLoading}
											placeholder={"Select medicine"}
											onChange={(medicine) => { this.handleChangeMedicine(medicine); }}
											required={true}
											// isValid={this.state.medicineError && this.state.medicineError === false ? true : false}
											isInvalid={this.state.medicineError ? true : false}
											isValid={this.state.medicineError ? false : true}
											minLength={1}
											onFocus={() => { this.setState({ isOpenMedicineTypehead: true }) }}
											onBlur={(e) => this.setState({ isOpenMedicineTypehead: false })}
											open={this.state.isOpenMedicineTypehead}
											selected={this.state.selectedMedicine && this.state.selectedMedicine.length > 0 ? this.state.selectedMedicine : []}
											clearButton
										/>
										<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
									</Form.Group>
								</div>
							</div>
							<div className="col-md-6">
								<Form.Group>
									<Form.Label><Translate id="medicine.medicine-type" /></Form.Label>
									<Typeahead
										id="medicineTypeId"
										labelKey="name"
										className={this.state.medicineTypeError ? "is-invalid" : "is-valid"}
										options={this.state.optionMedicineTypes}
										isLoading={this.state.isMedicineTypeLoading}
										placeholder={"Select type"}
										onChange={(type) => { this.handleChangeMedicineType(type); }}
										required={true}
										minLength={1}
										onFocus={() => { this.setState({ isOpenTypehead: true }) }}
										onBlur={(e) => this.setState({ isOpenTypehead: false })}
										open={this.state.isOpenTypehead}
										filterBy={() => true}
										selected={this.state.selectedMedicineType && this.state.selectedMedicineType.length > 0 ? this.state.selectedMedicineType : []}
										clearButton
										isValid={this.state.medicineTypeError && this.state.medicineTypeError === false ? true : false}
										isInvalid={this.state.medicineTypeError ? true : false}
									// isValid={this.state.medicineTypeError ? false : true}
									/>
									<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
								</Form.Group>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<Form.Group className="form-group" controlId="initialDosage">
										<Form.Label><Translate id="medicine.initial-dosage" /></Form.Label>
										<InputGroup className="mb-3">
											<Translate>
												{({ translate }) =>
													<FormControl
														type="text"
														name="initialDosage"
														value={this.state.initialDosage ? this.state.initialDosage : ""}
														onChange={(e) => { this.handleChange(e) }}
														placeholder={translate("medicine.initial-dosage")}
														required
													/>
												}
											</Translate>
											<InputGroup.Text id="basic-addon2">{webConst.shortType(webConst.ucfirst(this.state.medicineType))}</InputGroup.Text>
										</InputGroup>
										<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
									</Form.Group>
								</div>
							</div>
							<div className="col-md-6">
								<Form.Group className="form-group" controlId="packageId">
									<Form.Label><Translate id="medicine.strength" /></Form.Label>
									<Typeahead
										id="packageId"
										labelKey="name"
										className="is-valid"
										options={this.state.optionMedicineStrengthes}
										isLoading={this.state.isMedicineStrengthLoading}
										placeholder={"Select strength"}
										onChange={(strength) => {
											this.handleChangeMedicineStrength(strength);
										}}
										required={true}
										isValid={this.state.packageId > 0 ? true : false}
										minLength={1}
										onFocus={(e) => this.setState({ isOpenStrengthTypehead: true })}
										onBlur={(e) => this.setState({ isOpenStrengthTypehead: false })}
										open={this.state.isOpenStrengthTypehead}
										filterBy={() => true}
										selected={this.state.selectedMedicineStrength && this.state.selectedMedicineStrength.length > 0 ? this.state.selectedMedicineStrength : []}
										clearButton
									/>
									<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
								</Form.Group>
							</div>
							<div className="col-md-6">
								<Form.Group className="form-group" controlId="totalMg">
									<Form.Label><Translate id="medicine.total-mg" /></Form.Label>
									<InputGroup className="mb-3">
										<Translate>
											{({ translate }) =>
												<FormControl
													type="text"
													name="totalMg"
													value={totalMg}
													onChange={(e) => { this.handleChange(e) }}
													placeholder={translate("medicine.total-mg")}
													readOnly={true}
												/>
											}
										</Translate>
									</InputGroup>
								</Form.Group>
							</div>
							<div className="col-md-12">
								<Form.Group className="form-group" controlId="startDate">
									<Form.Label><Translate id="medicine.start-date" /></Form.Label>
									<Translate>
										{({ translate }) =>
											<DatePicker
												selected={this.state.startDate}
												name="startDate"
												onChange={(date) => {
													this.setDate("startDate", date)
												}}
												dateFormat="dd-MM-yyyy"
												className="form-control"
												withPortal
												locale={daLocale}
												value={this.state.startDate ? this.state.startDate : ""}
												placeholderText={translate("medicine.start-date")}
												autoComplete='off'
												required
											/>
										}
									</Translate>
									<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
								</Form.Group>
							</div>
							<div className="col-md-12">
								<Form.Group className="form-group" controlId="note">
									<Form.Label><Translate id="medicine.note" /></Form.Label>
									<Translate>
										{({ translate }) =>
											<Form.Control
												type="textarea"
												name="note"
												value={this.state.note ? this.state.note : ""}
												onChange={(e) => { this.handleChange(e) }}
												placeholder={translate("medicine.note")}
												as="textarea"
												rows="2"
												required
											/>
										}
									</Translate>
									<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
								</Form.Group>
							</div>
							<div className="col-md-12">
								<div className="form-group">
									<Form.Group className="form-group" controlId="medicineEffect">
										<Form.Label><Translate id="medicine.medicine-effect" /></Form.Label>
										<Translate>
											{({ translate }) =>
												<Form.Control
													type="textarea"
													name="medicineEffect"
													value={this.state.medicineEffect ? this.state.medicineEffect : ""}
													onChange={(e) => { this.handleChange(e) }}
													placeholder={translate("medicine.medicine-effect")}
													as="textarea"
													rows="2"
													required
												/>
											}
										</Translate>
										<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
									</Form.Group>
								</div>
							</div>
							<div className="row">
								<div className="col-md-12">
									<button type="submit" className="validi-theme-btn" name="create-reduction-plan">{webConst.isLoader(this.state.isFormLoading, <Translate id="general.save" />)}</button>
									<button type="reset" className="validi-theme-btn clear-btn" onClick={this.resetForm}><Translate id="general.clear" /></button>
								</div>
							</div>
						</Form>
					</div>
				</div>
			</div>
		)
	}
}


AddPN.defaultProps = {
	medicinePlanDetailSuccess: "",
	medicinePlanDetailStatus: "",
	medicinePlanDetailType: "none",
	pnPlanSuccess: "",
	pnPlanStatus: "",
	pnPlanType: "",
	pnActiveTab: "",
};

AddPN.propTypes = {
	medicinePlanDetailSuccess: PropTypes.any,
	medicinePlanDetailStatus: PropTypes.any,
	medicinePlanDetailType: PropTypes.any,
	pnPlanSuccess: PropTypes.any,
	pnPlanStatus: PropTypes.any,
	pnPlanType: PropTypes.any,
	pnActiveTab: PropTypes.any
};

function mapStateToProps(state) {
	return {
		medicinePlanDetailSuccess: state.medicinePlanDetail.success,
		medicinePlanDetailStatus: state.medicinePlanDetail.status,
		medicinePlanDetailType: state.medicinePlanDetail.type,
		pnPlanSuccess: state.pnPlan.success,
		pnPlanStatus: state.pnPlan.status,
		pnPlanType: state.pnPlan.type,
		pnActiveTab: state.pnPlan.pnActiveTab,
		medicineSuccess: state.medicine.success,
		medicineStatus: state.medicine.status,
		medicineType: state.medicine.type,
		editPn: state.pnPlan.editPn
	};
}

const mapDispatchToProps = {
	getMedicinePlanDetailRequest,
	getMedicineTypeRequest,
	getMedicineStrengthRequest,
	addPnMedicinePlanRequest,
	getMedicineRequest,
	updatePnMedicinePlanRequest,
	setPnActiveTab
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPN);
