import { JOURNAL_TYPE_SUCCESS, JOURNAL_TYPE_FAIL,ADD_JOURNAL_TYPE_SUCCESS, ADD_JOURNAL_TYPE_FAIL,UPDATE_JOURNAL_TYPE_SUCCESS,UPDATE_JOURNAL_TYPE_FAIL,DELETE_JOURNAL_TYPE_SUCCESS,DELETE_JOURNAL_TYPE_FAIL } from './Constants';
import axios from 'axios';
import { withHeader } from '../../../service/ApiConfig';
import * as webConst from '../../../utils/WebConstant';
const axiosDefaults = require('axios/lib/defaults');
axiosDefaults.baseURL = withHeader.baseURL;
axiosDefaults.headers = withHeader.headers;
axiosDefaults.timeout = withHeader.timeOut;

export const getJournalTypeRequest = (value) => {
    return dispatch => {
        axios.get(`${webConst.JOURNAL_TYPE}?`+value)
        .then((response) => {
            if(response.status===200)
            {
                dispatch(journalSuccessResult(response.data.data,response.status));
            }
            else{
                dispatch(journalFailResult(response.data.message,response.status));
            }
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                dispatch(journalFailResult(error.response.message,error.response.status));
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        }); 
    }
};

export const addJournalTypeRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.JOURNAL_TYPE}`,value)
        .then((response) => {
            if(response.status===200)
            {
                dispatch(addJournalSuccessResult(response.data.data,response.status));
            }
            else{
                dispatch(addJournalFailResult(response.data.message,response.status));
            }
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                dispatch(addJournalFailResult(error.response.data.message,error.response.status));
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        });
    }
}

export const updateJournalTypeRequest = (id,value) => {
    return dispatch => {
        axios.put(`${webConst.JOURNAL_TYPE}/`+id,value)
        .then((response) => {
            if(response.status===200)
            {
                dispatch(updateJournalSuccessResult(response.data.data,response.status));
            }
            else{
                dispatch(updateJournalFailResult(response.data.message,response.status));
            }
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                dispatch(updateJournalFailResult(error.response.data.message,error.response.status));
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        });
    }
};

export const deleteJournalTypeRequest = (value) => {
    return dispatch => {
        axios.delete(`${webConst.JOURNAL_TYPE}/`+value)
        .then((response) => {
            if(response.status===200)
            {
                dispatch(deleteJournalSuccessResult(response.data.data,response.status));
            }
            else{
                dispatch(deleteJournalFailResult(response.data.message,response.status));
            }
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                dispatch(deleteJournalFailResult(error.response.data.message,error.response.status));
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        });
    }
};

/*
    API RESULT
 */


export const journalSuccessResult = (success,status) => ({
    type: JOURNAL_TYPE_SUCCESS,
    success,
    status
});

export const journalFailResult = (error,status) => ({
    type: JOURNAL_TYPE_FAIL,
    error,
    status
});


export const addJournalSuccessResult = (success,status) => ({
    type: ADD_JOURNAL_TYPE_SUCCESS,
    success,
    status
});

export const addJournalFailResult = (error,status) => ({
    type: ADD_JOURNAL_TYPE_FAIL,
    error,
    status
});

export const updateJournalSuccessResult = (success,status) => ({
    type: UPDATE_JOURNAL_TYPE_SUCCESS,
    success,
    status
});

export const updateJournalFailResult = (error,status) => ({
    type: UPDATE_JOURNAL_TYPE_FAIL,
    error,
    status
});

export const deleteJournalSuccessResult = (success,status) => ({
    type: DELETE_JOURNAL_TYPE_SUCCESS,
    success,
    status
});

export const deleteJournalFailResult = (error,status) => ({
    type: DELETE_JOURNAL_TYPE_FAIL,
    error,
    status
});