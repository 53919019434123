import { MEDICINE_SUCCESS, MEDICINE_FAIL} from './Constants';
import axios from 'axios';
import { withHeader } from '../../../service/ApiConfig';
import * as webConst from '../../../utils/WebConstant';
const axiosDefaults = require('axios/lib/defaults');
axiosDefaults.baseURL = withHeader.baseURL;
axiosDefaults.headers = withHeader.headers;
axiosDefaults.timeout = withHeader.timeOut;

export const getMedicineRequest = (value) => {
    return dispatch => {
        axios.get(`${webConst.GET_MEDICINE}?search=`+value)
            .then((response) => {
                if(response.status===200){
                    dispatch(medicineSuccessResult(response.data.data, response.status));
                }
                else {
                    dispatch(medicineFailResult(response.data.data.message, response.status));
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    dispatch(medicineFailResult(error.response.message,error.response.status));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            }); 
    }
};

/*
    API RESULT
 */

export const medicineSuccessResult = (success, status) => ({
    type: MEDICINE_SUCCESS,
    success,
    status
});

export const medicineFailResult = (error, status) => ({
    type: MEDICINE_FAIL,
    error,
    status
});