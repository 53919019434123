import React, { Component, Fragment } from 'react';
// import Select from "react-select";
import { Tabs, Tab, Table, Form, FormGroup, Label, Input, InputGroup, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './AddDetox.scss';
import backArrow from '../../../../images/back-arrow.svg';
import * as routes from '../../../../utils/RouteConstant';
import * as webConst from '../../../../utils/WebConstant';
import { Translate } from 'react-localize-redux';
import moment from 'moment';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import daLocale from "date-fns/locale/da";
import { getMedicinePlanDetailRequest, getMedicineTypeRequest, getMedicineStrengthRequest } from '../../../../containers/Medicines/MedicinePlanDetailContainer/action';
import { GET_MEDICINE_PLAN_DETAIL_SUCCESS, MEDICINE_STRENGTH_SUCCESS, MEDICINE_TYPE_SUCCESS } from '../../../../containers/Medicines/MedicinePlanDetailContainer/Constants';
import { getMedicineStockRequest } from '../../../../containers/Medicines/StockContainer/action';
import { getDetoxPlanDetailRequest, addDetoxPlanRequest, updateDetoxPlanRequest } from '../../../../containers/Medicines/DetoxPlanContainer/action';
import { DETOX_PLAN_SUCCESS, ADD_DETOX_PLAN_SUCCESS, DETOX_PLAN_DETAIL_SUCCESS, UPDATE_DETOX_PLAN_SUCCESS } from '../../../../containers/Medicines/DetoxPlanContainer/Constants';
import { getMedicineRequest } from '../../../../containers/Medicines/MedicineContainer/action';
import { MEDICINE_SUCCESS } from '../../../../containers/Medicines/MedicineContainer/Constants';
import { setClientId } from '../../../../containers/AppLayoutContainer/action';
import PropTypes from 'prop-types';
// import AsyncSelect from 'react-select/async';
// import SelectSearch from 'react-select-search';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Medicine from '../../Medicine';
import { propTypes } from 'react-bootstrap/esm/Image';


class AddDetox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			'isLoading': true,
			'clientId': '',
			'clients': [],
			'medicines': [],
			'slotTimes': [],
			"medicineId": "",
			"initialDosage": "",
			"totalStrength": 0,
			"startDate": "",
			"startTime": "",
			"endDate": "",
			"decimalReduction": 0,
			"stocks": [],
			"packageId": "",
			"slotDosage": [],
			"slotDosageLength": 0,
			"detoxPlan": {},
			"detoxHeader": [],
			"detoxBody": [],
			"reductionType": 0,
			"validated": false,
			"medicineTypes": [],
			"optionMedicineTypes": [],
			"internalDoctors": [],
			"externalDoctors":[],
			"internalDoctorId":"",
			"externalDoctorId": "",
			"medicineStrengthes": [],
			"optionMedicineStrengthes": [],
			"medicineUnit": "mg",
			"medicineStrength": 0,
			"medicinePhoto": "",
			"medicineType": "Tabletter",
			"firstDosageDate": "",
			"selectedSlots": [],
			"reductionDays": [],
			"reductionSingleDayInMonth": 1,
			"isInfinateDosage": 0,
			"planGenerated": false,
			"note": "",
			"medicineTypeId": "",
			"isSaved": false,
			"formValidation": {},
			"dosages": {},
			"detoxId": "",
			"detox": [],
			"header": [],
			"reduction": [],
			"preDetoxDosage": [],
			"isCopy": 0,
			"isMedicineDetailLoaded": false,
			"medicineQty": "",
			"isOpenMedicineTypehead": false,
			"isOpenTypehead": false,
			"isOpenStrengthTypehead": false,
			"isMedicineLoading": false,
			"isMedicineTypeLoading": false,
			"isMedicineStrengthLoading": false,
			"selectedMedicine": {},
			"selectedMedicineType": {},
			"selectedMedicineStrength": {},
			"planLoading": false,
			"detoxFormLoading": false,
			"reductionDosage": "",
			"reductionDay": "",
			"doctorType":2
		};
		this.detoxForm = React.createRef();
		this.medicineId = React.createRef();
		this.medicineTypeId = React.createRef();
	}

	componentDidMount() {
		let _this = this;
		let client = webConst.getClient();
		let clientId = client && typeof client !== 'undefined' && typeof client.client_id !== 'undefined' ? client.client_id : 0;
		if (clientId > 0) {
			let clientName = client && typeof client !== 'undefined' && typeof client.client_name !== 'undefined' ? client.client_name : 0;
			let cpr = client && typeof client !== 'undefined' && typeof client.client_socialid !== 'undefined' ? client.client_socialid : 0;
			let photo = client && typeof client !== 'undefined' && typeof client.photo !== 'undefined' ? client.photo : 0;
			this.setState({ clientId: clientId, clientName: clientName, clientCPR: cpr, clientPhoto: photo, isDetoxLoading: true, pageNumber: 0 });

			let detoxId = "";
			if (this.props.match && this.props.match.params && this.props.match.params.detoxId) {
				detoxId = this.props.match.params.detoxId;
				this.setState({detoxId:detoxId});
				if (routes.DETOX_SUSPEND_ROUTE + "/:detoxId" === this.props.match.path) {
					this.setState({ isCopy: 1 });
				}
			}
			setTimeout(() => {
				_this.getMedicinePlanDetail();
			}, 100);
		}
		else {
			webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.select-client-message" />);
			this.props.history.push(routes.HOME_ROUTE);
		}
	}
	componentDidUpdate(prevProps) {
		let _this = this;
		const { medicinePlanDetailSuccess, medicinePlanDetailStatus, stockSuccess, stockStatus, homeSuccess, homeStatus, detoxSuccess, detoxStatus, detoxType, detoxId, medicinePlanDetailType, medicineSuccess, medicineStatus, medicineType } = this.props;

		if (homeStatus === 200) {
			if (homeSuccess && homeSuccess.client_list) {
				if (this.state.clients !== homeSuccess.client_list) {
					this.setState({ clients: homeSuccess.client_list });
					this.setState({ "clientId": webConst.getClientId() });
				}
			}
		}

		if (medicineStatus === 200) {
			if (medicineType === MEDICINE_SUCCESS && prevProps.medicineSuccess !== medicineSuccess) {
				// this.setMedicines(medicineSuccess);
				// setTimeout(() => {
					_this.setState({ medicines: medicineSuccess });	
				// }, 100);
				
				if (this.state.isMedicineLoading) {
					this.setState({
						isLoading: false,
						isMedicineLoading: false,
					});
				}
			}
		}
		else {
			if (this.state.isMedicineLoading) {
				this.setState({
					isLoading: false,
					isMedicineLoading: false,
				});
			}
		}

		if (medicinePlanDetailStatus === 200) {
			if (medicinePlanDetailType === GET_MEDICINE_PLAN_DETAIL_SUCCESS && prevProps.medicinePlanDetailSuccess !== medicinePlanDetailSuccess) {
				if (this.state.detoxId && this.state.isMedicineDetailLoaded === false) {
					setTimeout(() => {
						_this.getDetoxDetail();
					}, 100);
				}

				this.setInternalDoctors(medicinePlanDetailSuccess);
				this.setExternalDoctors(medicinePlanDetailSuccess);
				this.setTimeSlots(medicinePlanDetailSuccess);

				if (this.state.isLoading) {
					this.setState({
						isLoading: false,
						isMedicineLoading: false,
					});
				}
			}

			if (medicinePlanDetailType === MEDICINE_TYPE_SUCCESS && prevProps.medicinePlanDetailSuccess !== medicinePlanDetailSuccess) {
				if (Object.values(medicinePlanDetailSuccess.medicine_types).length) {
					let medicine_types = [];
					for (var index in medicinePlanDetailSuccess.medicine_types) {
						medicine_types.push({ value: index, name: webConst.ucfirst(medicinePlanDetailSuccess.medicine_types[index]) });
					}
					this.setState({ medicineTypes: medicinePlanDetailSuccess.medicine_types, optionMedicineTypes: medicine_types });
				}
				if (this.state.isMedicineTypeLoading) {
					this.setState({
						isLoading: false,
						isMedicineTypeLoading: false
					});
				}
			}

			if (medicinePlanDetailType === MEDICINE_STRENGTH_SUCCESS && prevProps.medicinePlanDetailSuccess !== medicinePlanDetailSuccess) {
				if (Object.values(medicinePlanDetailSuccess.medicine_strengths).length) {
					let medicineStrengthes = [];
					for (var index in medicinePlanDetailSuccess.medicine_strengths) {
						medicineStrengthes.push({ value: medicinePlanDetailSuccess.medicine_strengths[index].id, name: webConst.ucfirst(medicinePlanDetailSuccess.medicine_strengths[index].unit) });
					}
					this.setState({ medicineStrengthes: medicinePlanDetailSuccess.medicine_strengths, optionMedicineStrengthes: medicineStrengthes });
				}
				if (this.state.isMedicineStrengthLoading) {
					this.setState({
						isLoading: false,
						isMedicineStrengthLoading: false
					});
				}
			}
		}
		else {
			if (this.state.isLoading) {
				this.setState({
					isLoading: false,
					isMedicineLoading: false,
					isMedicineTypeLoading: false,
					isMedicineStrengthLoading: false
				});
			}
		}

		if (stockStatus == 200) {
			if (prevProps.stockSuccess !== stockSuccess) {
				this.setState({
					isLoading: false,
					isLoader: false
				});
				if (stockSuccess.stocks && stockSuccess.stocks.length) {
					this.setState({ stocks: stockSuccess.stocks });
				}
			}
		} else {
			if (this.state.isLoader) {
				this.setState({
					isLoading: false,
					isLoader: false
				});
			}
		}

		if (detoxStatus == 200) {
			if (prevProps.detoxSuccess !== detoxSuccess) {
				if (this.state.isLoading) {
					this.setState({
						isLoading: false,
						isLoader: false
					});
				}

				if (detoxSuccess && detoxType == DETOX_PLAN_SUCCESS) {
					this.setState({ detox: detoxSuccess.detox, isUpdateMedicineStrength: true });
					if (detoxSuccess.medicine_types && detoxSuccess.medicine_types.length) {
						let medicine_types = [];
						for (var index in detoxSuccess.medicine_types) {
							medicine_types.push({ value: detoxSuccess.medicine_types[index].id, name: webConst.ucfirst(detoxSuccess.medicine_types[index].medicine_type) });
						}
						this.setState({ medicineTypes: detoxSuccess.medicine_types, optionMedicineTypes: medicine_types, isMedicineTypeLoading: false });
					}
					if (detoxSuccess.medicine_strengths && detoxSuccess.medicine_strengths.length) {
						let medicineStrengthes = [];
						for (var index in detoxSuccess.medicine_strengths) {
							medicineStrengthes.push({ value: detoxSuccess.medicine_strengths[index].id, name: webConst.ucfirst(detoxSuccess.medicine_strengths[index].unit) });
						}
						this.setState({ medicineStrengthes: detoxSuccess.medicine_strengths, optionMedicineStrengthes: medicineStrengthes, isMedicineStrengthLoading: false });
					}
					if (detoxSuccess.pre_detox_dosage && detoxSuccess.pre_detox_dosage.length) {
						this.setState({ preDetoxDosage: detoxSuccess.pre_detox_dosage });
					}
					console.log("detoxId",this.state.detoxId);
					if (this.state.detoxId) {
						setTimeout(() => {
							_this.setEditDetox();
						}, 500);
					}
				}

				if (detoxSuccess.detox && detoxType == ADD_DETOX_PLAN_SUCCESS) {
					let detox = detoxSuccess.detox;
					let detoxPlan = { "header": detox.slots, "reduction": detox.records }
					this.setState({ detoxPlan: detoxPlan });
					if (detox.detoxSaved) {
						this.setState({ "validated": true });
						this.detoxForm.current.classList.remove("was-validated");
						this.detoxForm.current.reset();
						this.resetForm();
						webConst.toaster(webConst.TOAST_SUCCESS, <div><Translate id="medicine.detox-plan" /> <Translate id="alert-message.saved-message" /></div>);
						this.props.history.push(routes.DETOX_PLAN_ROUTE);
					}
					else if (detox.error_date != "") {
						webConst.toaster(webConst.TOAST_ERROR, "You should choose " + detox.plan_end_date + " end date");
					}
				}

				if (detoxSuccess.detox && detoxType == UPDATE_DETOX_PLAN_SUCCESS) {
					let detox = detoxSuccess.detox;
					let detoxPlan = { "header": detox.slots, "reduction": detox.records }
					this.setState({ detoxPlan: detoxPlan });
					if (detox.detoxSaved) {
						this.setState({ "validated": true });
						this.detoxForm.current.classList.remove("was-validated");
						this.detoxForm.current.reset();
						this.resetForm();
						webConst.toaster(webConst.TOAST_SUCCESS, <div><Translate id="medicine.detox-plan" /> <Translate id="alert-message.saved-message" /></div>);
						this.props.history.push(routes.DETOX_PLAN_ROUTE);
					}
					else if (detox.error_date != "") {
						webConst.toaster(webConst.TOAST_ERROR, "You should choose " + detox.plan_end_date + " end date");
					}
				}

				if (detoxType === DETOX_PLAN_DETAIL_SUCCESS) {
					let detox = detoxSuccess.detox;
					this.setState({ detox: detox, isUpdateMedicineStrength: true, medicineType: "Tabletter" });
					if (prevProps.detoxSuccess !== detoxSuccess) {
						if (detoxSuccess.timeslots && Object.values(detoxSuccess.timeslots).length) {
							this.setState({ slotTimes: detoxSuccess.timeslots });
						}

						if (detoxSuccess.medicine_types && Object.keys(detoxSuccess.medicine_types).length) {
							let medicine_types = [];
							for (var index in detoxSuccess.medicine_types) {
								medicine_types.push({ value: index, name: webConst.ucfirst(detoxSuccess.medicine_types[index]) });
							}
							this.setState({ medicineTypes: detoxSuccess.medicine_types, optionMedicineTypes: medicine_types });
						}
						if (detoxSuccess.medicine_strengthes && Object.keys(detoxSuccess.medicine_strengthes).length) {
							let medicineStrengthes = [];
							for (var index in detoxSuccess.medicine_strengthes) {
								medicineStrengthes.push({ value: detoxSuccess.medicine_strengthes[index].id, name: webConst.ucfirst(detoxSuccess.medicine_strengthes[index].unit) });
							}
							this.setState({ medicineStrengthes: detoxSuccess.medicine_strengthes, optionMedicineStrengthes: medicineStrengthes });
						}
						if (detoxSuccess.pre_detox_dosage && detoxSuccess.pre_detox_dosage.length) {
							this.setState({ preDetoxDosage: detoxSuccess.pre_detox_dosage });
						}
						if (this.state.detoxId) {
							setTimeout(() => {
								_this.setEditDetox();
							}, 500);
						}
					}
				}
			}
		}
		else {
			if (this.state.isLoading) {
				this.setState({
					isLoading: false,
				});
			}
			if (detoxSuccess === 400) {
				webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.something-wrong" />);
			}
		}
	}

	setMedicines(data) {
		let _this = this;
		if (data && Object.keys(data).length) {
			let medicine_data = [];
			for (var index in data) {
				medicine_data.push({ value: data[index].id, name: webConst.ucfirst(data[index].name) });
				_this.setState(prevState => ({
					medicines: medicine_data
				}));
			}
			_this.setState({ isMedicineLoading: false });
			// console.log(medicine_data);
			setTimeout(() => {
				_this.setState({ medicines: medicine_data });
			}, 10);
		}
	}
		
	setMedicines1(data) {
		let _this = this;
		if (data && Object.keys(data).length) {
			// console.log("data",data);
			let medicine_data = [];
			_this.setState({medicines: medicine_data});

			for (var index in data) {
				medicine_data.push({ value: data[index].id, name: webConst.ucfirst(data[index].name) });
				// var medicine={ value: data[index].id, name: webConst.ucfirst(data[index].name) }
				// console.log(medicine);
				// this.setState((prevState) => ({ medicines: [...prevState.medicines,medicine] }));
			}
			_this.setState({ isMedicineLoading: false });
			console.log(medicine_data);
			_this.setState({medicines: []});
			setTimeout(() => {
				_this.setState({ medicines: medicine_data });
			}, 10);
		}
	}

	setInternalDoctors(data) {
		if (data.doctors && Object.values(data.doctors).length) {
			this.setState({ internalDoctors: data.doctors });
		}
	}
	
	setExternalDoctors(data) {
		if (data.external_doctors && Object.values(data.external_doctors).length) {
			this.setState({ externalDoctors: data.external_doctors });
		}
	}
	
	setTimeSlots(medicinePlanDetailSuccess) {
		let _this = this;
		if (medicinePlanDetailSuccess.timeslots && Object.values(medicinePlanDetailSuccess.timeslots).length) {
			let timeSlots = medicinePlanDetailSuccess.timeslots;
			let selectedSlots = {};
			let slotDosages = {};
			// let sortedTime=this.timeSort(medicinePlanDetailSuccess.timeslots);
			let sortTimeSlots = {};
			timeSlots.forEach(slot => {
				let slot_id = slot.id;
				if (!selectedSlots[slot_id])
					selectedSlots[slot_id] = false;
				if (!slotDosages[slot_id])
					slotDosages[slot_id] = 0;
				if (!sortTimeSlots[slot_id])
					sortTimeSlots[slot_id] = "";
			});
			setTimeout(() => {
				_this.setState({ selectedSlots: selectedSlots, slotDosages: slotDosages, slotTimes: timeSlots });
			}, 100);
		}
	}

	getDetoxDetail = () => {
		this.props.getDetoxPlanDetailRequest(this.state.detoxId);
	}
	resetForm = () => {
		let slotDosages = [];
		Object.keys(this.state.slotTimes).forEach(slot_id => {
			if (!slotDosages[slot_id])
				slotDosages[slot_id] = 0;
		});
		this.setState({
			"medicineId": "",
			"initialDosage": "",
			"medicineQty": 0,
			"totalStrength": 0,
			"startDate": "",
			"endDate": "",
			"decimalReduction": 0,
			"packageId": "",
			"slotDosageLength": 0,
			"reductionType": 0,
			"validated": false,
			"doctorId": "",
			"medicineUnit": "mg",
			"medicineStrength": 0,
			"medicineType": "Tabletter",
			"firstDosageDate": "",
			"selectedSlots": [],
			"slotDosages": [],
			"reductionDays": [],
			"reductionSingleDayInMonth": 0,
			"isInfinateDosage": 0,
			"planGenerated": false,
			"note": "",
			"medicineTypeId": "",
			"slotTimes": [],
			"reductionDosage": 1,
			"reductionDay": 1,
			"isSaved": false,
			"startTime": "",
			"slotTimeDosages": "",
			"medicinePhoto": "",
			"detoxId": "",
			"detoxFormLoading": false
		});
		this.detoxForm.current.reset();
	}

	handleSetClient = (e) => {
		let id = e.target.value;
		this.setState({ 'clientId': id });
		let _this = this;
		_this.props.setClientId(id);
		// webConst.setClientId(id);
	}

	handleChange = (event) => {
		let _this = this;
		let name = event.target.name;
		let value = event.target.value;
		if (name == 'initialDosage') {
			this.totalStrength();
			this.setState({ [name]: value, medicineQty: value });
			setTimeout(() => {
				_this.handleUpdateSlotTablet();
			}, 100);
		}
		else if (name == 'startTime') {
			this.setState({ ["slotId"]: value });
			let selectedSlots = this.state.selectedSlots;
			Object.entries(selectedSlots).forEach(item => {
				selectedSlots[item[0]] = false;
			});

			selectedSlots[value] = !selectedSlots[value];
			let slotDosageLength = 0;
			setTimeout(() => {
				if (_this.state.selectedSlots != selectedSlots)
					_this.setState({ selectedSlots: selectedSlots });

				if (selectedSlots[value] === true)
					_this.setState({ slotDosageLength: slotDosageLength += 1 });
				else
					_this.setState({ slotDosageLength: slotDosageLength -= 1 });
			}, 100);

			let index = event.nativeEvent.target.selectedIndex;
			this.setState({ [name]: value, startTimeTxt: event.nativeEvent.target[index].text });
			this.handleUpdateSlotTablet();
		}
		else if (name === "selectedSlots") {
			let selectedSlots = this.state.selectedSlots;
			selectedSlots[value] = event.target.checked;
			this.setState({ selectedSlots: selectedSlots });
			let slotDosageLength = this.state.slotDosageLength;
			if (selectedSlots[value] === true)
				this.setState({ slotDosageLength: slotDosageLength += 1 });
			else
				this.setState({ slotDosageLength: slotDosageLength -= 1 });
			this.handleUpdateSlotTablet();
			this.setState({ formValidation: { ...this.state.formValidation, [name]: (slotDosageLength ? true : false) } });
		}
		else if (name === 'decimalReduction') {
			this.setState({ [name]: event.target.checked ? 1 : 0 });
			setTimeout(() => {
				_this.handleUpdateSlotTablet();
			}, 100);
		}
		else if (name === "isInfinateDosage") {
			this.setState({ [name]: event.target.checked ? 1 : 0, reductionType: 0 });
		}
		else {
			this.setState({ [name]: value });
		}

	}

	handleChangeMedicine = (medicine) => {
		if (medicine.length) {
			let isOpenMedicineTypehead = (this.state.isOpenMedicineTypehead === true ? false : true);
			this.getMedicineType(medicine[0].value);
			this.setState({ medicineType: "Tabletter", isMedicineTypeLoading: true, isOpenMedicineTypehead: isOpenMedicineTypehead });
			if (this.state.medicineId !== medicine[0].value)
				this.setState({ medicineId: medicine[0].value, medicineTypeId: "", packageId: "", selectedMedicine: medicine, selectedMedicineType: [], selectedMedicineStrength: [] });
		} else {
			this.setState({ medicines: [], medicineId: "", medicineTypeId: "", packageId: "", selectedMedicine: [], selectedMedicineType: [], selectedMedicineStrength: [], optionMedicineTypes: [], optionMedicineStrengthes: [] });
		}
	}

	handleChangeMedicineType = (type) => {
		let _this = this;
		let isOpenTypehead = this.state.isOpenTypehead === true ? false : true;
		if (type.length) {
			this.getMedicineStrength(type[0].value);

			Object.keys(this.state.medicineTypes).filter((index) => {
				if (index == type[0].value) {
					_this.setState({ medicineType: webConst.ucfirst(_this.state.medicineTypes[index]) });
				}
			});

			this.setState({
				medicineTypeId: type[0].value,
				isOpenTypehead: isOpenTypehead,
				medicinePhoto: "",
				medicineUnit: "mg",
				medicineStrength: 0,
				selectedMedicineType: type,
				isMedicineStrengthLoading: true,
				selectedMedicineStrength: [],
				packageId: "",
			});
		} else {
			this.setState({ medicineTypeId: "", packageId: "", selectedMedicineType: [], selectedMedicineStrength: [] });
		}
	}
	
	

	handleChangeMedicineStrength = (strength) => {
		let _this = this;
		if (strength.length) {
			let value = strength[0].value;
			this.state.medicineStrengthes.filter((data) => {
				if (data.id == value) {
					_this.setState({ medicinePhoto: data.image, medicineUnit: data.unit, medicineStrength: data.unit_strength });
				}
			});
			let packageId = strength[0].value;
			let isOpenStrengthTypehead = this.state.isOpenStrengthTypehead === true ? false : true;
			this.setState({ isOpenStrengthTypehead: isOpenStrengthTypehead, packageId: packageId, selectedMedicineStrength: strength });
			this.totalStrength();
		} else {
			this.setState({ packageId: "", selectedMedicineStrength: [] });
		}
	}

	setSlotDosage = (slotId, value) => {
		let slotDosages = this.state.slotDosages;
		slotDosages[slotId] = value;
		this.setState({ slotDosages: slotDosages });
		if (value > 0)
			this.handleUpdateSlotTablet();
		else {
			setTimeout(() => {
				this.handleUpdateSlotTablet();
			}, 500);
		}
	}

	totalStrength = () => {
		let total = 0;
		let initialDosage = this.state.initialDosage ? this.state.initialDosage : 0;
		let medicineStrength = this.state.medicineStrength ? this.state.medicineStrength : 0;
		let totalStrength = medicineStrength * initialDosage;
		this.setState({ totalStrength: totalStrength });
	}

	handleUpdateSlotTablet = () => {
		let _this = this;
		let slotDosages = this.state.slotDosages;
		let slotTimeDosages = {};
		setTimeout(() => {
			let initialDosage = this.state.initialDosage ? this.state.initialDosage : 0;
			let medicineStrength = this.state.medicineStrength ? this.state.medicineStrength : 0;
			let totalStrength = medicineStrength * initialDosage;
			_this.setState({ totalStrength: totalStrength });
			let selectedSlots = _this.state.selectedSlots;
			let slotLength = _this.state.slotDosageLength;
			let endTime = "";
			if (slotLength > 0) {
				let dosage = initialDosage / slotLength;
				if (this.state.decimalReduction === 0) {
					let decimalTotal = 0;
					let firstKey = "";
					for (const key in selectedSlots) {
						if (Object.hasOwnProperty.call(selectedSlots, key)) {
							if (firstKey == "")
								firstKey = key;
							const element = selectedSlots[key];
							if (element === true) {
								var intPart = Math.trunc(dosage);
								decimalTotal += Number((dosage - intPart));
								slotDosages[key] = intPart;
								endTime = key;
								if (!slotTimeDosages[key])
									slotTimeDosages[key] = "";
								slotTimeDosages[key] = slotDosages[key];
							}
							else
								slotDosages[key] = 0;
						}
					}

					if (slotTimeDosages[firstKey]) {
						slotTimeDosages[firstKey] = Math.round(slotTimeDosages[firstKey] + decimalTotal);
						slotDosages[firstKey] = Math.round(slotDosages[firstKey] + decimalTotal);
					}
					setTimeout(() => {
						_this.setState({ slotDosages: slotDosages, slotTimeDosages: slotTimeDosages });
					}, 100);
				} else {
					for (const key in selectedSlots) {
						if (Object.hasOwnProperty.call(selectedSlots, key)) {
							const element = selectedSlots[key];
							if (element === true) {
								slotDosages[key] = dosage.toFixed(1);
								endTime = key;
								if (slotTimeDosages[key])
									slotTimeDosages[key] = "";
								slotTimeDosages[key] = slotDosages[key];
							}
							else
								slotDosages[key] = 0;
						}
						_this.setState({ slotDosages: slotDosages, slotTimeDosages: slotTimeDosages });
					}
					_this.setState({ endTime: endTime });
				}
			}
		}, 500);
	}

	handleUpdateSlotDosage = () => {
		let _this = this;
		let slotDosages = this.state.slotDosages;
		let slotTimeDosages = {};
		setTimeout(() => {
			let initialDosage = this.state.initialDosage ? this.state.initialDosage : 0;
			let medicineStrength = this.state.medicineStrength ? this.state.medicineStrength : 0;
			let totalStrength = medicineStrength * initialDosage;
			_this.setState({ totalStrength: totalStrength });
			let selectedSlots = _this.state.selectedSlots;
			let slotLength = _this.state.slotDosageLength;
			let endTime = "";
			if (slotLength > 0) {
				let dosage = totalStrength / slotLength;
				if (this.state.decimalReduction === 0) {
					let decimalTotal = 0;
					let firstKey = "";
					for (const key in selectedSlots) {
						if (Object.hasOwnProperty.call(selectedSlots, key)) {
							if (firstKey == "")
								firstKey = key;
							const element = selectedSlots[key];
							if (element === true) {
								var intPart = Math.trunc(dosage);
								decimalTotal += Number((dosage - intPart));
								slotDosages[key] = intPart;
								endTime = key;
								if (!slotTimeDosages[key])
									slotTimeDosages[key] = "";
								slotTimeDosages[key] = slotDosages[key];
							}
							else
								slotDosages[key] = 0;
						}
					}

					if (slotTimeDosages[firstKey]) {
						slotTimeDosages[firstKey] = Math.round(slotTimeDosages[firstKey] + decimalTotal);
						slotDosages[firstKey] = Math.round(slotDosages[firstKey] + decimalTotal);
					}
					setTimeout(() => {
						_this.setState({ slotDosages: slotDosages, slotTimeDosages: slotTimeDosages });
					}, 100);
				} else {
					for (const key in selectedSlots) {
						if (Object.hasOwnProperty.call(selectedSlots, key)) {
							const element = selectedSlots[key];
							if (element === true) {
								slotDosages[key] = dosage.toFixed(1);
								endTime = key;
								if (slotTimeDosages[key])
									slotTimeDosages[key] = "";
								slotTimeDosages[key] = slotDosages[key];
							}
							else
								slotDosages[key] = 0;
						}
						_this.setState({ slotDosages: slotDosages, slotTimeDosages: slotTimeDosages });
					}
					_this.setState({ endTime: endTime });
				}
			}
		}, 500);
	}

	getMedicinePlanDetail = (e) => {
		this.setState({ 'isLoading': true, isMedicineLoading: true });
		const params = new URLSearchParams();
		params.append('clinic_id', webConst.getClinicId());
		this.props.getMedicinePlanDetailRequest(params);
	}

	getMedicineType = (medicineId) => {
		this.setState({ 'isMedicineTypeLoading': true });
		const params = new URLSearchParams();
		params.append('medicine_id', medicineId);
		this.props.getMedicineTypeRequest(params);
	}

	getMedicineStrength = (medicineTypeId) => {
		let medicineId = this.state.medicineId;
		if (medicineId > 0 && medicineTypeId > 0) {
			this.setState({ 'isMedicineStrengthLoading': true });
			const params = new URLSearchParams();
			params.append('medicine_id', medicineId);
			params.append('medicine_type_id', medicineTypeId);
			this.props.getMedicineStrengthRequest(params);
		}
	}

	getMedicineStock = (medicineId) => {
		this.setState({ 'isLoading': true });
		const params = new URLSearchParams();
		params.append('clinic_id', webConst.getClinicId());
		params.append('medicine_id', medicineId);
		this.props.getMedicineStockRequest(params);
	}

	handleDetoxSubmit = (event) => {
		event.preventDefault();
		if (this.detoxForm.current.checkValidity() === false) {
			this.setState({ "validated": false });
			this.detoxForm.preventDefault();
			this.detoxForm.stopPropagation();
		}
		else {
			this.setState({ isSaved: true });
			this.setState({ "isLoading": true, detoxFormLoading: true });
			this.saveDetox(1);
		}
		this.setState({ "validated": true });
	}

	handleSubmit = (event) => {
		event.preventDefault();
		this.setState({ "isSaved": false });
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			this.setState({ "validated": false, "isPlanLoading": false });
			event.preventDefault();
			event.stopPropagation();
		}
		else {
			this.saveDetox(0);
			this.setState({ planGenerated: true, "isPlanLoading": false });
		}
		this.setState({ "validated": true });
	}

	saveDetox = (status) => {
		const params = new URLSearchParams();
		if(this.state.doctorType==2)
			params.append('doctor_id', this.state.internalDoctorId);
		else
			params.append('doctor_id', this.state.externalDoctorId);
		params.append('detox_id', this.state.detoxId);
		params.append('doctor_type', this.state.doctorType);
		params.append('user_id', webConst.getUserId());
		params.append('client_id', webConst.getClientId());
		params.append('medicine_id', this.state.medicineId);
		params.append('initial_amount', this.state.medicineQty);
		params.append('medicine_qty', this.state.medicineQty);
		params.append('start_date', moment(this.state.startDate).format("YYYY-MM-DD"));
		params.append('first_dosage_date', moment(this.state.firstDosageDate).format("YYYY-MM-DD"));
		if (this.state.isInfinateDosage == 1)
			params.append('end_date', this.state.startDate ? moment(this.state.startDate).add("Year", "1").format("YYYY-MM-DD") : "");
		else
			params.append('end_date', this.state.endDate ? moment(this.state.endDate).format("YYYY-MM-DD") : "");
		params.append('next_reduction_amount', this.state.reductionDosage);
		params.append('next_reduction_days', this.state.reductionDay);
		params.append('is_decimal_reduction', this.state.decimalReduction);
		params.append('once_day_in_month', this.state.reductionSingleDayInMonth);
		params.append('package_id', this.state.packageId);
		params.append('slot_dosages', JSON.stringify(this.state.slotTimeDosages));
		params.append('medicine_strength', this.state.medicineStrength);
		params.append('is_infinate_dosage', this.state.isInfinateDosage);
		params.append('type', this.state.reductionType);
		params.append('remark', this.state.note ? this.state.note : "");
		params.append('dispense_days', this.state.reductionDays);
		params.append('is_save', status);
		params.append('first_slot_id', this.state.startTime);
		params.append('detox_dosages', JSON.stringify(this.state.dosages));
		params.append('is_copy', this.state.isCopy);
		if (this.state.detoxId > 0 && this.state.isCopy==0)
			this.props.updateDetoxPlanRequest(params, this.state.detoxId);
		else
			this.props.addDetoxPlanRequest(params);
	}

	setDate = (name, value) => {
		if (name == "startDate") {
			this.setState({ startDate: value });
			if (this.state.firstDosageDate == "")
				this.setState({ firstDosageDate: value });
		}
		else {
			this.setState({ [name]: value });
		}
	}

	setReductionDay = (event) => {
		let reductionDays = this.state.reductionDays;
		if (event.target.checked) {
			reductionDays.push(event.target.value);
		} else {
			reductionDays = reductionDays.filter((item) => {
				return item !== event.target.value;

			});
		}
		this.setState({ reductionDays: reductionDays });
	}

	handleDetoxDosageChange = (slotId, date, amount) => {
		let dosages = this.state.dosages;
		if (!dosages[date])
			dosages[date] = {};
		if (!dosages[date][slotId])
			dosages[date][slotId] = false;
		if (dosages[date][slotId])
			delete dosages[date][slotId];
		else
			dosages[date][slotId] = amount;
		this.setState({ dosages: dosages });
	}

	setEditDetox = () => {
		console.log("setEditDetox",this.state.detox.doctor_id);
		let _this = this;
		let startSlotId = "";
		let selectedSlots = {};
		let slotDosages = {};
		if (this.state.detox && this.state.slotTimes) {
			let startSlot = Object.keys(this.state.slotTimes).filter((id) => {
				var slot = this.state.slotTimes[id];
				var id = slot.id;
				if (_this.state.detox.slot_time.includes(parseInt(id))) {
					if (!selectedSlots[id])
						selectedSlots[id] = true;
					if (_this.state.detox.slot_dosages[id]) {
						if (!slotDosages[id])
							slotDosages[id] = _this.state.detox.slot_dosages[id];
					}
				}
				else {
					if (!selectedSlots[id])
						selectedSlots[id] = false;
					if (!slotDosages[id])
						slotDosages[id] = 0;
				}
			});
		}

		if (this.state.detox) {
			console.log(this.state.detox);
			let detox = {
				"medicineId": this.state.detox.medicine_id,
				"initialDosage": this.state.detox.medicine_qty,
				"medicineQty": this.state.detox.medicine_qty,
				"totalStrength": this.state.detox.initial_amount,
				"startDate": moment(this.state.detox.start_date, "YYYY-MM-DD").toDate(),
				"endDate": moment(this.state.detox.end_date, "YYYY-MM-DD").toDate(),
				"decimalReduction": parseInt(this.state.detox.is_decimal_reduction),
				"packageId": this.state.detox.package_id,
				"slotDosageLength": this.state.detox.slot_time.length,
				"reductionType": parseInt(this.state.detox.type),
				"validated": false,
				"internalDoctorId": this.state.detox.doctor_type=="2" ? parseInt(this.state.detox.doctor_id) : "",
				"externalDoctorId": this.state.detox.doctor_type=="3" ? parseInt(this.state.detox.doctor_id) : "",
				"doctorType" : parseInt(this.state.detox.doctor_type),
				"medicineUnit": this.state.detox.medicine_size,
				"medicineStrength": this.state.detox.medicine_strength,
				"medicineType": this.state.detox.medicine_type,
				"firstDosageDate": moment(this.state.detox.first_dosage_date, "YYYY-MM-DD").toDate(),
				"selectedSlots": selectedSlots,
				"slotDosages": slotDosages,
				"reductionDays": this.state.detox.dispense_days ? this.state.detox.dispense_days : [],
				"reductionSingleDayInMonth": this.state.detox.once_day_in_month ? parseInt(this.state.detox.once_day_in_month) : 0,
				"isInfinateDosage": parseInt(this.state.detox.is_infinate_dosage),
				"planGenerated": false,
				"note": this.state.detox.remark,
				"medicineTypeId": this.state.detox.medicine_type_id,
				// "slotTimes": this.state.detox.time_slots,
				"reductionDosage": this.state.detox.next_reduction_amount ? this.state.detox.next_reduction_amount : 0,
				"reductionDay": this.state.detox.next_reduction_days ? this.state.detox.next_reduction_days : 0,
				"isSaved": false,
				"startTime": this.state.detox.first_slot_id,
				"slotTimeDosages": slotDosages,
				"medicinePhoto": this.state.detox.medicine_image
			};
			this.handleSearch(this.state.detox.medicine.name);
			this.setState(detox);
			this.handleUpdateSlotTablet();
			var selectedItem = [{ "value": this.state.detox.medicine.id, "name": this.state.detox.medicine.name }];
			_this.setState({ selectedMedicine: selectedItem });
			selectedItem = [];
			this.state.optionMedicineTypes.filter((data) => {
				if (data.value == _this.state.detox.medicine_type_id) {
					selectedItem.push(data);
					_this.setState({ selectedMedicineType: selectedItem });
				}
			});

			selectedItem = [];
			this.state.optionMedicineStrengthes.filter((data) => {
				if (data.value == _this.state.detox.package_id) {
					selectedItem.push(data);
					_this.setState({ selectedMedicineStrength: selectedItem });
				}
			});
		}

	}

	medicineFilter = (query) => {
		var medicines = [];
		let _this = this;
		setTimeout(() => {
			medicines = _this.state.medicines.filter((val) => {
				if (query == "") {
					return val
				}
				else if (val.name.toLowerCase().includes(query.toLowerCase())) {
					return val;
				}
			}).map((val, key) => {
				return val;
			});
		}, 100);
		return medicines;
	}

	timeSort = (timeslots) => {
		return Object.values(timeslots).sort((a, b) => moment(a, "HH:ii:ss").valueOf() - moment(b, "HH:ii:ss").valueOf());
	}

	handleSearch = (query) => {
		if (this.state.isMedicineLoading === false) {
			this.setState({ isMedicineLoading: true });
			this.props.getMedicineRequest(query);
		}
	}

	render() {
		let dayOptions = [];
		let _this = this;
		dayOptions.push(<Translate key="day-01">{({ translate }) => <option key={"day-0"} value="">{translate("medicine.select-day")}</option>}</Translate>);
		for (let i = 1; i <= 31; i++) {
			dayOptions.push(<option key={"day-" + i} value={i}>{i}</option>);
		}
		let dayNames = ["medicine.monday", "medicine.tuesday", "medicine.wednesday", "medicine.thursday", "medicine.friday", "medicine.saturday", "medicine.sunday"];
		let initialDosage = (this.state.initialDosage ? this.state.initialDosage : 0);
		let medicineStrength = (this.state.medicineStrength ? this.state.medicineStrength : 0);
		let medicineUnit = this.state.medicineUnit ? this.state.medicineUnit : "";
		let totalStrength = initialDosage * medicineStrength;

		let detoxHeader = [];
		let detoxBody = [];
		let reductions = [];
		if (this.state.detoxPlan.header) {
			Object.keys(this.state.detoxPlan.header).map(item => {
				let slotTime = this.state.detoxPlan.header[item];
				let checked = item === this.state.startTime ? true : false;
				detoxHeader.push(<th key={"slot-header-" + item} className="validi-radio-btnbox">
					<Form.Check type={"radio"} id={`radio-list-09:01`} className="radio-btn">
						<Form.Check.Input type="radio" name="detoxSlot[]" value={item} onChange={this.updateDetoxPlan} checked={checked} disabled={true} />
						<span className="checkmark"></span>
						<Form.Check.Label>{slotTime}</Form.Check.Label>
					</Form.Check>
				</th>
				);
			});

			let reductions = this.state.detoxPlan.reduction;
			for (const key in reductions) {
				if (Object.hasOwnProperty.call(reductions, key)) {
					let reduction = reductions[key];
					let td = [];
					td.push(<td key={"detox-dosage-" + key} className="validi-date-text">{key}</td>);
					for (const slotId in reduction) {
						if (Object.hasOwnProperty.call(reduction, slotId)) {
							var dosage = parseFloat(reduction[slotId]["dosage_amt"]);
							td.push(<td key={"detox-dosage-" + key + "-" + slotId} className="">
								<Form.Check type={"checkbox"} id={"dosage-checkbox-" + key + "-" + slotId} className="radio-btn">
									<Form.Check.Input
										type="checkbox"
										name="dosage[]"
										value={dosage}
										onChange={(e) => { _this.handleDetoxDosageChange(slotId, key, e.target.value) }}
										defaultChecked={this.state.dosages[key] && this.state.dosages[key][slotId] && this.state.dosages[key][slotId] > 0 ? this.state.dosages[key][slotId] : false}
									/>
									<span className="checkmark"></span>
									<Form.Check.Label>
										{dosage.toFixed(1)} {webConst.shortType(webConst.ucfirst(this.state.medicineType))} ({dosage.toFixed(1) * medicineStrength} {medicineUnit.replace(/[^a-zA-Z\/]+/g, '')})
									</Form.Check.Label>
								</Form.Check>
							</td>);
						}
					}
					let tr = <tr key={"dosage-row-" + key}>{td}</tr>;
					detoxBody.push(tr);
				}
			}
		}
		
		return (
			<div className="validi-main-layout validi-detox-medicine-layout">
				<div className="container">
					<div className="validi-back-btn-wrapper">
						<Link to={routes.DETOX_PLAN_ROUTE}>
							<i><img src={backArrow} alt="back-arrow" /></i>
							<Translate id="medicine.back-to-detox" />
						</Link>
					</div>
					{this.props.match && this.props.match.params && this.props.match.params.detoxId > 0 ?
						<h1 className="main-title"><Translate id="medicine.edit-detox-plan" /></h1>
						:
						<h1 className="main-title"><Translate id="medicine.add-detox-plan" /></h1>
					}
					<div className="validi-medicine-wrapper">
						<div className="validi-inner-header-bar">
							<div className="validi-avatar-details-wrepper">
								<div className="validi-avatar-img">
									{this.state.clientPhoto ? <img src={this.state.clientPhoto} alt="" /> : ""}
								</div>
								<div className="validi-avatar-details">
									<h5 className="validi-user-main-title">{this.state.clientName ? this.state.clientName : ""}</h5>
									<p className="validi-paragraph"><Translate id="client-goal.cpr-number" /> : {this.state.clientCPR ? this.state.clientCPR : ""}</p>
								</div>
							</div>
							<div className="validi-select-input">
							</div>
						</div>
						<div className="validi-add-detox-layout">
							<div className="container">
								<div className="validi-medicine-wrapper">
									<div className="">
										<Form className="row" noValidate validated={this.state.validated} onSubmit={this.handleSubmit} ref={this.detoxForm}>
											<div className="col-md-6">
												<div className="row">
													<div className="col-md-6">
													<Form.Group className="form-group" controlId="interalDoctorId">
														<div className="validi-radio-btnbox">
															<Form.Check type={"radio"} id={`internal-doctor`} className="radio-btn">
																<Form.Check.Input type="radio" name="doctorType" value="2" onChange={(e) => { this.handleChange(e) }} checked={this.state.doctorType == 2} />
																<span className="checkmark"></span>
																<Form.Check.Label><Translate id="general.select-internal-doctor" /></Form.Check.Label>
															</Form.Check>
														</div>
														{this.state.doctorType && this.state.doctorType == 2 ?
															<div>
																<Form.Select
																	type="select"
																	name="internalDoctorId"
																	value={this.state.internalDoctorId ? this.state.internalDoctorId : ""}
																	onChange={(e) => { this.handleChange(e) }}
																	className="mt-2"
																	required
																	placeholder="Select doctor"
																>
																	<Translate>
																		{({ translate }) =>
																			<option key="internal-doctor-0" value="">{translate("general.select-internal-doctor")}</option>
																		}
																	</Translate>
																	{ this.state.internalDoctors ? 
																		Object.keys(_this.state.internalDoctors).map(function(id){
																			return <option value={id} key={"internal-doctor-"+id}>{_this.state.internalDoctors[id]}</option>
																		})
																		:""
																	}
																</Form.Select>
																<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
															</div>
															: ""
														}
													</Form.Group>
												</div>
												<div className="col-md-6">
													<Form.Group className="form-group" controlId="externalDoctorId">
														<div className="validi-radio-btnbox mb-2">
															<Form.Check type={"radio"} id={`external-doctor`} className="radio-btn">
																<Form.Check.Input type="radio" name="doctorType" value="3" onChange={(e) => { this.handleChange(e) }} checked={this.state.doctorType == 3} />
																<span className="checkmark"></span>
																<Form.Check.Label><Translate id="general.select-external-doctor" /></Form.Check.Label>
															</Form.Check>
														</div>
														{this.state.doctorType && this.state.doctorType == 3 ?
															<div>
																<Form.Select
																	type="select"
																	name="externalDoctorId"
																	value={this.state.externalDoctorId ? this.state.externalDoctorId : ""}
																	onChange={(e) => { this.handleChange(e) }}
																	className="mt-2"
																	placeholder="Select doctor"
																	required
																>
																	<Translate>
																		{({ translate }) =>
																			<option key="external-doctor-0" value="">{translate("general.select-external-doctor")}</option>
																		}
																	</Translate>
																	{ this.state.externalDoctors ? 
																			Object.keys(this.state.externalDoctors).map(function(id){
																				return <option key={"external-doctor-" + id} value={id}>
																					{_this.state.externalDoctors[id]}
																				</option>
																			})
																		:""
																	}
																</Form.Select>
																<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
															</div>
															: ""
														}
													</Form.Group>
												</div>
													{/* <div className="col-md-6">
														<Form.Group className="form-group" controlId="interalDoctorId">
															<Form.Label><Translate id="medicine.doctor" /></Form.Label>
															{
																this.state.doctors && Object.values(this.state.doctors).length ?
																	<div>
																		<Form.Select
																			type="select"
																			name="doctorId"
																			value={this.state.doctorId ? this.state.doctorId : ""}
																			onChange={(e) => { this.handleChange(e) }}
																			className=""
																			required
																			placeholder="Select doctor"
																		>
																			<Translate>
																				{({ translate }) =>
																					<option key="internal-doctor-0" value="">{translate("general.select-internal-doctor")}</option>
																				}
																			</Translate>
																			{
																				Object.keys(this.state.doctors).map((index) => {
																					return <option key={"doctor-" + index} value={index}>
																						{webConst.ucfirst(_this.state.doctors[index])}
																					</option>
																				})
																			}
																		</Form.Select>
																		<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
																	</div>
																	: ""
															}
														</Form.Group>
													</div> */}
													<div className="col-md-6">
														<Form.Group className="form-group" controlId="medicineId">
															<Form.Label><Translate id="medicine.medicine" /></Form.Label>
															<AsyncTypeahead
																id="medicineId"
																labelKey="name"
																isLoading={this.state.isMedicineLoading}
																onSearch={(e) => this.handleSearch(e)}
																options={this.state.medicines && this.state.medicines.length ? this.state.medicines : []}
																className="is-valid"
																placeholder={"Type medicine name"}
																onChange={(medicine) => { this.handleChangeMedicine(medicine); }}
																required={true}
																isValid={this.state.medicineId > 0 ? true : false}
																minLength={1}
																onFocus={() => { this.setState({ isOpenMedicineTypehead: true }) }}
																onBlur={(e) => this.setState({ isOpenMedicineTypehead: false })}
																open={this.state.isOpenMedicineTypehead}
																selected={this.state.selectedMedicine && this.state.selectedMedicine.length > 0 ? this.state.selectedMedicine : []}
																filterBy={() => true}
																clearButton
															/>
															<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
														</Form.Group>
													</div>
													<div className="col-md-6">
														<Form.Group className="form-group" controlId="medicineTypeId">
															<Form.Label><Translate id="medicine.medicine-type" /></Form.Label>
															{this.state.optionMedicineTypes && Object.keys(this.state.optionMedicineTypes).length ?
																<Translate>
																	{({ translate }) =>
																		<Typeahead
																			id="medicineTypeId"
																			labelKey="name"
																			className="is-valid"
																			options={this.state.optionMedicineTypes}
																			isLoading={this.state.isMedicineTypeLoading ? true : false}
																			placeholder={translate("medicine.medicine-type")}
																			onChange={(type) => { this.handleChangeMedicineType(type); }}
																			required={true}
																			isValid={this.state.medicineTypeId > 0 ? true : false}
																			minLength={1}
																			onFocus={() => { this.setState({ isOpenTypehead: true }) }}
																			onBlur={(e) => this.setState({ isOpenTypehead: false })}
																			open={this.state.isOpenTypehead}
																			filterBy={() => true}
																			selected={this.state.selectedMedicineType && this.state.selectedMedicineType.length > 0 ? this.state.selectedMedicineType : []}
																			clearButton
																		/>
																	}
																</Translate>
																:
																<Translate>
																	{({ translate }) =>
																		<Form.Control
																			type="text"
																			name="medicineTypeId"
																			onChange={(e) => { this.handleChange(e) }}
																			placeholder={translate("medicine.medicine-type")}
																		/>
																	}
																</Translate>
															}
															<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
														</Form.Group>
													</div>
													<div className="col-md-6">
														<Form.Group className="form-group" controlId="packageId">
															<Form.Label><Translate id="medicine.strength" /></Form.Label>
															{this.state.optionMedicineStrengthes && Object.keys(this.state.optionMedicineStrengthes).length ?
																<Typeahead 
																	id="packageId"
																	labelKey="name"
																	className="is-valid"
																	options={this.state.optionMedicineStrengthes}
																	isLoading={this.state.isMedicineStrengthLoading}
																	placeholder={"Select strength"}
																	onChange={(strength) => {
																		this.handleChangeMedicineStrength(strength);
																	}}
																	required={true}
																	isValid={this.state.packageId > 0 ? true : false}
																	minLength={1}
																	onFocus={(e) => this.setState({ isOpenStrengthTypehead: true })}
																	onBlur={(e) => this.setState({ isOpenStrengthTypehead: false })}
																	open={this.state.isOpenStrengthTypehead}
																	filterBy={() => true}
																	selected={this.state.selectedMedicineStrength && this.state.selectedMedicineStrength.length > 0 ? this.state.selectedMedicineStrength : []}
																	clearButton
																/> :
																<Translate>
																	{({ translate }) =>
																		<Form.Control
																			type="text"
																			name="packageId"
																			onChange={(e) => { this.handleChange(e) }}
																			placeholder={translate("medicine.strength")}
																		/>
																	}
																</Translate>
															}
															<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
														</Form.Group>
													</div>
													<div className="col-md-6">
														<Form.Group className="form-group" controlId="initialDosage">
															<Form.Label><Translate id="medicine.initial-dosage" /></Form.Label>
															<InputGroup className="mb-3">
																<Translate>
																	{({ translate }) =>
																		<FormControl
																			type="text"
																			name="initialDosage"
																			value={this.state.initialDosage ? this.state.initialDosage : ""}
																			onChange={(e) => { this.handleChange(e) }}
																			placeholder={translate("medicine.initial-dosage")}
																			required
																		/>
																	}
																</Translate>
																<InputGroup.Text id="basic-addon2">{this.state.medicineType ? webConst.shortType(webConst.ucfirst(this.state.medicineType)) : ""}</InputGroup.Text>
															</InputGroup>
															<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
														</Form.Group>
													</div>
													<div className="col-md-6">
														<Form.Group className="form-group" controlId="total">
															<Form.Label><Translate id="medicine.total" /></Form.Label>
															<div>
																{this.state.medicineType ?
																	<>
																		{initialDosage} {webConst.shortType(webConst.ucfirst(this.state.medicineType))} * {medicineUnit} = {totalStrength} {medicineUnit.replace(/[^a-zA-Z\/]+/g, '')}
																	</>
																	: ""
																}
															</div>
														</Form.Group>
													</div>
													<div className="col-md-6">
														<Form.Group className="form-group" controlId="startDate">
															<Form.Label><Translate id="medicine.start-date" /></Form.Label>
															<Translate>
																{({ translate }) =>
																	<DatePicker
																		selected={this.state.startDate ? this.state.startDate : ""}
																		name="startDate"
																		onChange={(date) => {
																			this.setDate("startDate", date)
																		}}
																		dateFormat="dd-MM-yyyy"
																		className="form-control"
																		withPortal
																		locale={daLocale}
																		value={this.state.startDate ? this.state.startDate : ""}
																		placeholderText={translate("medicine.start-date")}
																		autoComplete='off'
																		required
																	/>
																}
															</Translate>
															<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
														</Form.Group>
													</div>
													<div className="col-md-6">
														<Form.Group className="form-group" controlId="firstDosageDate">
															<Form.Label><Translate id="medicine.first-dosage-date" /></Form.Label>
															<Translate>
																{({ translate }) =>
																	<DatePicker
																		selected={this.state.firstDosageDate ? this.state.firstDosageDate : ""}
																		name="firstDosageDate"
																		onChange={(date) => { this.setDate("firstDosageDate", date) }}
																		dateFormat="dd-MM-yyyy"
																		className="form-control text-normal"
																		withPortal
																		locale={daLocale}
																		value={this.state.firstDosageDate ? this.state.firstDosageDate : ""}
																		placeholderText={translate("medicine.first-dosage-date")}
																		autoComplete='off'
																		required
																	/>
																}
															</Translate>
															<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
														</Form.Group>
													</div>
													<div className="col-md-6">
														<Form.Group className="form-group" controlId="startTime">
															<Form.Label><Translate id="medicine.start-time" /></Form.Label>
															<Translate>
																{({ translate }) =>
																	<Form.Select
																		type="select"
																		name="startTime"
																		value={this.state.startTime ? this.state.startTime : ""}
																		onChange={(e) => { this.handleChange(e) }}
																		placeholder={translate("medicine.start-time")}
																		required
																	>
																		<Translate>
																			{({ translate }) =>
																				<option key="start-time-0" value="">{translate("medicine.start-time")}</option>
																			}
																		</Translate>
																		{this.state.slotTimes ?
																			_this.state.slotTimes.map((timeSlot) => {
																				return <option key={"start-time-" + timeSlot.id} value={timeSlot.id}>
																					{moment(timeSlot.slot, "HH:mm:ss").format("HH:mm")}
																				</option>
																			})
																			: ""
																		}
																	</Form.Select>
																}
															</Translate>
															<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
														</Form.Group>
													</div>
													<div className="col-md-6">
														<Form.Group className="form-group" controlId="note">
															<Form.Label><Translate id="medicine.why-give-medcine" /></Form.Label>
															<Translate>
																{({ translate }) =>
																	<Form.Control
																		type="textarea"
																		name="note"
																		value={this.state.note ? this.state.note : ""}
																		onChange={(e) => { this.handleChange(e) }}
																		placeholder={translate("medicine.note")}
																		as="textarea"
																		rows="2"
																	/>
																}
															</Translate>
															<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
														</Form.Group>
													</div>
													<div className="col-md-6">
														{this.state.medicinePhoto ? <img src={webConst.getMedicineUrl(this.state.medicinePhoto)} width={"95%"} /> : ""}
													</div>
												</div>
											</div>
											<div className="col-md-6">
												<div className="row">
													<div className="col-md-12">
														<div className="form-group">
															<label><Translate id="medicine.slot-timing-for-medicine-plan" /></label>
															<div className="slot-box">
																{
																	_this.state.slotTimes ?
																		_this.state.slotTimes.map((slotTimes) => {
																			return <div key={"checkbox-" + slotTimes.id} className="validi-slot-timing-wrapper">
																				<div className="validi-checkbox-btnbox">
																					<Form.Check type={"checkbox"} id={"checkbox-" + slotTimes.id} className="radio-btn">
																						<Form.Check.Input type="checkbox" name="selectedSlots"
																							checked={this.state.selectedSlots && this.state.selectedSlots[slotTimes.id] && this.state.selectedSlots[slotTimes.id] == true ? true : false}
																							value={slotTimes.id}
																							onChange={(e) => { this.handleChange(e) }}
																							isInvalid={(this.state.formValidation.selectedSlots === false) && this.state.slotDosageLength == 0}
																						/>
																						<span className="checkmark"></span>
																						<Form.Check.Label>
																							{slotTimes.slot}
																						</Form.Check.Label>
																					</Form.Check>
																				</div>
																				{this.state.selectedSlots && this.state.selectedSlots[slotTimes.id] && this.state.selectedSlots[slotTimes.id] == true ?
																					<>
																						<Translate>
																							{({ translate }) =>
																								<FormControl
																									type="text"
																									name="slotDosages"
																									value={this.state.slotDosages && this.state.slotDosages[slotTimes.id] ? this.state.slotDosages[slotTimes.id] : ""}
																									onChange={(e) => { this.setSlotDosage(slotTimes.id, e.target.value) }}
																									placeholder={translate('medicine.slot-time-dosage')}
																								/>
																							}
																						</Translate>
																						<InputGroup.Text id="basic-addon2">{this.state.medicineType ? webConst.shortType(webConst.ucfirst(this.state.medicineType)) : ""}</InputGroup.Text>
																					</>
																					: ""
																				}
																			</div>
																		})
																		: ""
																}
															</div>
														</div>
													</div>
													{this.state.isInfinateDosage === 0 ?
														<div className="col-md-5">
															<div className="validi-finished-date-block">

																<Form.Group className="form-group" controlId="endDate">
																	<Form.Label><Translate id="medicine.end-date" /></Form.Label>
																	<Translate>
																		{({ translate }) =>
																			<DatePicker
																				selected={this.state.endDate ? this.state.endDate : ""}
																				name="endDate"
																				onChange={(date) => {
																					this.setDate("endDate", date)
																				}}
																				dateFormat="dd-MM-yyyy"
																				className="form-control"
																				withPortal
																				locale={daLocale}
																				value={this.state.endDate ? this.state.endDate : ""}
																				placeholderText={translate("medicine.end-date")}
																				autoComplete='off'
																				required
																			/>
																		}
																	</Translate>
																	<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
																</Form.Group>
															</div>
														</div>
														: ""
													}
													<div className="col-md-5">
														<label>&nbsp;</label>
														<Form.Group className="form-group">
															<div className="validi-checkbox-btnbox">
																<Form.Check type={"checkbox"} id={`checkbox-decimal-reduction`} className="checkbox-btn">
																	<Form.Check.Input type="checkbox" name="decimalReduction" checked={this.state.decimalReduction === 1 ? true : false} value={1} onChange={(e) => { this.handleChange(e) }} />
																	&nbsp;<span className="checkmark"></span>
																	<Form.Check.Label><Translate id="medicine.decimal-reduction" /></Form.Check.Label>
																</Form.Check>
																<Form.Check type={"checkbox"} id={`checkbox-infinate-dosage`} className="checkbox-btn">
																	<Form.Check.Input type="checkbox" name="isInfinateDosage" checked={this.state.isInfinateDosage === 1 ? true : false} value={1} onChange={(e) => { this.handleChange(e) }} />
																	&nbsp;<span className="checkmark"></span>
																	<Form.Check.Label><Translate id="medicine.until-suspended" /></Form.Check.Label>
																</Form.Check>
															</div>
														</Form.Group>
													</div>
													{this.state.isInfinateDosage === 0 ?
														<div className="col-md-5">
															<div className="form-group">
																<label><Translate id="medicine.next-reduction-dosage" /></label>

																<div>
																	{
																		this.state.reductionType === 0 ?
																			<div>
																				<div className="validi-radio-btnbox mt-2 mb-2">
																					<Form.Check type={"radio"} id={`radio-every-day`} className="radio-btn">
																						<Form.Check.Input type="radio" name="reductionType" checked={true} value={0} onChange={(e) => { this.setState({ reductionType: 0 }); }} />
																						<span className="checkmark"></span>
																						<Form.Check.Label><Translate id="medicine.selected-every-day" /></Form.Check.Label>
																					</Form.Check>
																				</div>
																				<div className="validi-radio-btnbox  mt-2 mb-2">
																					<Form.Check type={"radio"} id={`radio-specific-day`} className="radio-btn">
																						<Form.Check.Input type="radio" name="reductionType" checked={false} value={1} onChange={(e) => { this.setState({ reductionType: 1 }); }} />
																						<span className="checkmark"></span>
																						<Form.Check.Label><Translate id="medicine.select-specific-day" /></Form.Check.Label>
																					</Form.Check>
																				</div>
																				<div className="validi-radio-btnbox  mt-2 mb-2">
																					<Form.Check type={"radio"} id={`radio-single-day-in-month`} className="radio-btn">
																						<Form.Check.Input type="radio" name="reductionType" checked={false} value={2} onChange={(e) => { this.setState({ reductionType: 2 }); }} />
																						<span className="checkmark"></span>
																						<Form.Check.Label><Translate id="medicine.select-single-day-month" /></Form.Check.Label>
																					</Form.Check>
																				</div>
																			</div>
																			: ""
																	}
																</div>

																<div>
																	{this.state.reductionType === 1 ?
																		<div>
																			<div className="validi-radio-btnbox mt-2 mb-2">
																				<Form.Check type={"radio"} id={`radio-every-day`} className="radio-btn">
																					<Form.Check.Input type="radio" name="reductionType" checked={false} value={0} onChange={(e) => { this.setState({ reductionType: 0 }); }} />
																					<span className="checkmark"></span>
																					<Form.Check.Label><Translate id="medicine.selected-every-day" /></Form.Check.Label>
																				</Form.Check>
																			</div>
																			<div className="validi-radio-btnbox  mt-2 mb-2">
																				<Form.Check type={"radio"} id={`radio-specific-day`} className="radio-btn">
																					<Form.Check.Input type="radio" name="reductionType" checked={true} value={1} onChange={(e) => { this.setState({ reductionType: 1 }); }} />
																					<span className="checkmark"></span>
																					<Form.Check.Label><Translate id="medicine.select-specific-day" /></Form.Check.Label>
																				</Form.Check>
																			</div>
																			<div className="validi-radio-btnbox  mt-2 mb-2">
																				<Form.Check type={"radio"} id={`radio-single-day-in-month`} className="radio-btn">
																					<Form.Check.Input type="radio" name="reductionType" checked={false} value={2} onChange={(e) => { this.setState({ reductionType: 2 }); }} />
																					<span className="checkmark"></span>
																					<Form.Check.Label><Translate id="medicine.select-single-day-month" /></Form.Check.Label>
																				</Form.Check>
																			</div>
																		</div>
																		: ""
																	}
																</div>

																<div>
																	{
																		this.state.reductionType === 2 ?
																			<div>
																				<div className="validi-radio-btnbox mt-2 mb-2">
																					<Form.Check type={"radio"} id={`radio-every-day`} className="radio-btn">
																						<Form.Check.Input type="radio" name="reductionType" checked={false} value={0} onChange={(e) => { this.setState({ reductionType: 0 }); }} />
																						<span className="checkmark"></span>
																						<Form.Check.Label><Translate id="medicine.selected-every-day" /></Form.Check.Label>
																					</Form.Check>
																				</div>
																				<div className="validi-radio-btnbox  mt-2 mb-2">
																					<Form.Check type={"radio"} id={`radio-specific-day`} className="radio-btn">
																						<Form.Check.Input type="radio" name="reductionType" checked={false} value={1} onChange={(e) => { this.setState({ reductionType: 1 }); }} />
																						<span className="checkmark"></span>
																						<Form.Check.Label><Translate id="medicine.select-specific-day" /></Form.Check.Label>
																					</Form.Check>
																				</div>
																				<div className="validi-radio-btnbox  mt-2 mb-2">
																					<Form.Check type={"radio"} id={`radio-single-day-in-month`} className="radio-btn">
																						<Form.Check.Input type="radio" name="reductionType" checked={true} value={2} onChange={(e) => { this.setState({ reductionType: 2 }); }} />
																						<span className="checkmark"></span>
																						<Form.Check.Label><Translate id="medicine.select-single-day-month" /></Form.Check.Label>
																					</Form.Check>
																				</div>
																			</div>
																			: ""
																	}
																</div>

															</div>
														</div> : ""
													}
													<div className="col-md-5">
														<div>
															{this.state.reductionType === 0 && this.state.isInfinateDosage === 0 ?
																<Form.Group className="form-group" controlId="reductionDay">
																	<Form.Label><Translate id="medicine.next-reduction-day" /></Form.Label>
																	<Translate>
																		{({ translate }) =>
																			<Form.Control
																				type="text"
																				name="reductionDay"
																				value={this.state.reductionDay}
																				onChange={(e) => { this.handleChange(e) }}
																				placeholder={translate("medicine.next-reduction-day")}
																				required
																			/>
																		}
																	</Translate>
																	<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
																</Form.Group>
																: ""
															}
															{this.state.reductionType === 1 && this.state.isInfinateDosage === 0 ?
																<Form.Group className="form-group" controlId="reductionDay">
																	<Form.Label><Translate id="medicine.day" /></Form.Label>
																	<div>
																		{
																			dayNames.map((item, index) => {
																				return <div key={item + index}>
																					<div className="validi-checkbox-btnbox mt-2 mb-2">
																						<Form.Check type={"checkbox"} id={"reduction-days-" + index} className="radio-btn">
																							<Form.Check.Input
																								type="checkbox"
																								name="reductionDays"
																								defaultChecked={_this.state.reductionDays && _this.state.reductionDays.includes(item.replace("medicine.", "")) ? true : false}
																								value={item.replace("medicine.", "")}
																								onChange={(e) => { _this.setReductionDay(e) }} />
																							<span className="checkmark"></span>
																							<Form.Check.Label>
																								<Translate id={item} />
																							</Form.Check.Label>
																						</Form.Check>
																					</div>
																				</div>
																			})
																		}
																	</div>
																	<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
																</Form.Group>
																: ""
															}{this.state.reductionType === 2 && this.state.isInfinateDosage === 0 ?
																<Form.Group className="form-group" controlId="reductionSingleDayInMonth">
																	<Form.Label><Translate id="medicine.dispense-every-day-in-month" /></Form.Label>
																	<Form.Select
																		type="select"
																		name="reductionSingleDayInMonth"
																		value={this.state.reductionSingleDayInMonth ? this.state.reductionSingleDayInMonth : ""}
																		onChange={(e) => { this.handleChange(e) }}
																		required
																	>
																		{dayOptions}
																	</Form.Select>
																	<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
																</Form.Group>
																: ""
															}
															{this.state.isInfinateDosage === 0 ?
																<Form.Group className="form-group" controlId="reductionDosage">
																	<Form.Label><Translate id="medicine.next-reduction-dosage" /></Form.Label>
																	<Translate>
																		{({ translate }) =>
																			<div>
																				<Form.Control
																					type="text"
																					name="reductionDosage"
																					value={this.state.reductionDosage}
																					onChange={(e) => { this.handleChange(e) }}
																					placeholder={translate("medicine.next-reduction-dosage")}
																					required
																				/>
																			</div>
																		}
																	</Translate>
																	<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
																</Form.Group> : ""
															}
														</div>
													</div>
													<div className="col-md-5">
														<div className="form-group">
															<div className="validi-create-plan">
																<button type="submit" className="btn btn-link text-success" name="create-reduction-plan"><Translate id="medicine.create-reduction-plan" /></button>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className='row'>
												<div className="col-md-5">
													{
														this.state.planGenerated ? <div>
															<button type="button" className="validi-theme-btn" onClick={(event) => { this.handleDetoxSubmit(event) }} name="create-reduction-plan">{webConst.isLoader(this.state.detoxFormLoading, <Translate id="general.save" />)}</button>
															<button type="reset" className="validi-theme-btn clear-btn" onClick={this.resetForm}><Translate id="general.clear" /></button>
														</div>
															: ""
													}
												</div>
											</div>
										</Form>


										<div className="validi-reduction-plan-block">
											{this.state.detoxPlan.reduction ?
												<div className="validi-reduction-plan-table">
													<div className="validi-tabbar-inner-header">
														<div className="">
															<h4 className="validi-subtitle"><Translate id="medicine.reduction" /></h4>
														</div>
													</div>
													<div className="validi-table-data-wrapper">
														<Table responsive="md">
															<thead>
																<tr>
																	<th><Translate id="medicine.reduction-date" /></th>
																	{detoxHeader}
																</tr>
															</thead>
															<tbody>
																{detoxBody}
															</tbody>
														</Table>
													</div>
													<div className='row'>
														<div className="col-md-5">
															{
																this.state.planGenerated ? <div>
																	<button type="button" className="validi-theme-btn" onClick={(event) => { this.handleDetoxSubmit(event) }} name="create-reduction-plan">{webConst.isLoader(this.state.detoxFormLoading, <Translate id="general.save" />)}</button>
																	<button type="reset" className="validi-theme-btn clear-btn" onClick={this.resetForm}><Translate id="general.clear" /></button>
																</div>
																	: ""
															}
														</div>
													</div>
												</div>
												: ""
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

AddDetox.defaultProps = {
	homeSuccess: "",
	homeStatus: "",
	medicinePlanDetailSuccess: "",
	medicinePlanDetailStatus: "",
	medicinePlanDetailType: "",
	stockSuccess: "",
	stockStatus: "",
	detoxSuccess: "",
	detoxStatus: "",
	detoxType: "",
	medicines:{}
};

AddDetox.propTypes = {
	homeSuccess: PropTypes.any,
	homeStatus: PropTypes.any,
	medicinePlanDetailSuccess: PropTypes.any,
	medicinePlanDetailStatus: PropTypes.any,
	medicinePlanDetailType: PropTypes.any,
	stockSuccess: PropTypes.any,
	stockStatus: PropTypes.any,
	detoxSuccess: PropTypes.any,
	detoxStatus: PropTypes.any,
	medicines: PropTypes.any.isRequired
	// detoxPlanType: propTypes.any,
};


function mapStateToProps(state) {
	return {
		homeSuccess: state.home.success,
		homeStatus: state.home.status,
		medicineSuccess: state.medicine.success,
		medicineStatus: state.medicine.status,
		medicineType: state.medicine.type,
		medicinePlanDetailSuccess: state.medicinePlanDetail.success,
		medicinePlanDetailStatus: state.medicinePlanDetail.status,
		medicinePlanDetailType: state.medicinePlanDetail.type,
		stockSuccess: state.stock.stockSuccess,
		stockStatus: state.stock.status,
		detoxSuccess: state.detoxPlan.success,
		detoxStatus: state.detoxPlan.status,
		detoxType: state.detoxPlan.type,
	};
}

const mapDispatchToProps = {
	getMedicinePlanDetailRequest,
	getMedicineStockRequest,
	addDetoxPlanRequest,
	updateDetoxPlanRequest,
	setClientId,
	getMedicineTypeRequest,
	getMedicineStrengthRequest,
	getDetoxPlanDetailRequest,
	getMedicineRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDetox);
