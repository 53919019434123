import { PERSONAL_JOURNAL_SUCCESS, PERSONAL_JOURNAL_FAIL,ADD_PERSONAL_JOURNAL_SUCCESS, ADD_PERSONAL_JOURNAL_FAIL,UPDATE_PERSONAL_JOURNAL_SUCCESS,UPDATE_PERSONAL_JOURNAL_FAIL,DELETE_PERSONAL_JOURNAL_SUCCESS,DELETE_PERSONAL_JOURNAL_FAIL } from './Constants';
import axios from 'axios';
import { withHeader } from '../../../service/ApiConfig';
import * as webConst from '../../../utils/WebConstant';
const axiosDefaults = require('axios/lib/defaults');
axiosDefaults.baseURL = withHeader.baseURL;
axiosDefaults.headers = withHeader.headers;
axiosDefaults.timeout = withHeader.timeOut;

export const getPersonalJournalRequest = (value) => {
    return dispatch => {
        axios.get(`${webConst.PERSONAL_JOURNAL}?`+value)
        .then((response) => {
            if(response.status===200)
            {
                dispatch(journalSuccessResult(response.data.data,response.status));
            }
            else{
                dispatch(journalFailResult(response.data.data,response.status));
            }
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                dispatch(journalFailResult(error.response.data.message,error.response.status));
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        }); 
    }
};

export const saveJournalRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.PERSONAL_JOURNAL}`,value)
        .then((response) => {
            if(response.status===200)
            {
                dispatch(addJournalSuccessResult(response.data.data,response.status));
            }
            else{
                dispatch(addJournalFailResult(response.data.message,response.status));
            }
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                dispatch(addJournalFailResult(error.response.data.message,error.response.status));
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        });
    }
};

export const updateJournalRequest = (id,value) => {
    return dispatch => {
        axios.put(`${webConst.PERSONAL_JOURNAL}/`+id,value)
        .then((response) => {
            if(response.status===200)
            {
                dispatch(updateJournalSuccessResult(response.data.data,response.status));
            }
            else{
                dispatch(updateJournalFailResult(response.data.message,response.status));
            }
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                dispatch(updateJournalFailResult(error.response.data.message,error.response.status));
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        });
    }
};

export const deleteJournalRequest = (id) => {
    return dispatch => {
        axios.delete(`${webConst.PERSONAL_JOURNAL}/`+id)
        .then((response) => {
            if(response.status===200)
            {
                dispatch(deleteJournalSuccessResult(response.data.data,response.status));
            }
            else{
                dispatch(deleteJournalFailResult(response.data.message,response.status));
            }
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                dispatch(deleteJournalFailResult(error.response.data.message,error.response.status));
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        });
    }
};

/*
    API RESULT
 */
export const journalSuccessResult = (success,status) => ({
    type: PERSONAL_JOURNAL_SUCCESS,
    success,
    status
});

export const journalFailResult = (error,status) => ({
    type: PERSONAL_JOURNAL_FAIL,
    error,
    status
});


export const addJournalSuccessResult = (success,status) => ({
    type: ADD_PERSONAL_JOURNAL_SUCCESS,
    success,
    status
});

export const addJournalFailResult = (error,status) => ({
    type: ADD_PERSONAL_JOURNAL_FAIL,
    error,
    status
});

export const updateJournalSuccessResult = (success,status) => ({
    type: UPDATE_PERSONAL_JOURNAL_SUCCESS,
    success,
    status
});

export const updateJournalFailResult = (error,status) => ({
    type: UPDATE_PERSONAL_JOURNAL_FAIL,
    error,
    status
});

export const deleteJournalSuccessResult = (success,status) => ({
    type: DELETE_PERSONAL_JOURNAL_SUCCESS,
    success,
    status
});

export const deleteJournalFailResult = (error,status) => ({
    type: DELETE_PERSONAL_JOURNAL_FAIL,
    error,
    status
});
