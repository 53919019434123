import { GET_MEDICINE_PLAN_DETAIL_SUCCESS, GET_MEDICINE_PLAN_DETAIL_FAIL, MEDICINE_TYPE_SUCCESS, MEDICINE_TYPE_FAIL, MEDICINE_STRENGTH_SUCCESS, MEDICINE_STRENGTH_FAIL } from './Constants';
import axios from 'axios';
import { withHeader } from '../../../service/ApiConfig';
import * as webConst from '../../../utils/WebConstant';
const axiosDefaults = require('axios/lib/defaults');
axiosDefaults.baseURL = withHeader.baseURL;
axiosDefaults.headers = withHeader.headers;
axiosDefaults.timeout = withHeader.timeOut;

export const getMedicinePlanDetailRequest = (value) => {
    return dispatch => {
        axios.get(`${webConst.GET_MEDICINE_PLAN_DETAIL}`, value)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(medicineSuccessResult(response.data.data, response.status));
                }
                else {
                    dispatch(medicineFailResult(response.data.message,response.status));
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    dispatch(medicineFailResult(error.response.message,error.response.status));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            }); 
    }
};

export const getMedicineTypeRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.GET_MEDICINE_TYPE}`, value)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(medicineTypeSuccessResult(response.data.data, response.status));
                }
                else {
                    dispatch(medicineTypeFailResult(response.data.message,response.status));
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    dispatch(medicineTypeFailResult(error.response.data.message,error.response.status));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            }); 
    }
};

export const getMedicineStrengthRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.GET_MEDICINE_STRENGTH}`, value)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(medicineStrengthSuccessResult(response.data.data, response.status));
                }
                else {
                    dispatch(medicineStrengthFailResult(response.data.message,response.status));
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    dispatch(medicineStrengthFailResult(error.response.data.message,error.response.status));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            }); 
    }
};

/*
    API RESULT
 */


export const medicineSuccessResult = (success, status) => ({
    type: GET_MEDICINE_PLAN_DETAIL_SUCCESS,
    success,
    status
});

export const medicineFailResult = (error, status) => ({
    type: GET_MEDICINE_PLAN_DETAIL_FAIL,
    error,
    status
});


export const medicineTypeSuccessResult = (success, status) => ({
    type: MEDICINE_TYPE_SUCCESS,
    success,
    status
});

export const medicineTypeFailResult = (error, status) => ({
    type: MEDICINE_TYPE_FAIL,
    error,
    status
});

export const medicineStrengthSuccessResult = (success, status) => ({
    type: MEDICINE_STRENGTH_SUCCESS,
    success,
    status
});

export const medicineStrengthFailResult = (error, status) => ({
    type: MEDICINE_STRENGTH_FAIL,
    error,
    status
});