import React, { Component, Fragment } from 'react';
import './PNDispenseHistory.scss';
import { FormGroup, Label, Input, Form, InputGroup, FormControl, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as routes from '../../../../utils/RouteConstant';
import backArrow from '../../../../images/back-arrow.svg';
import { Translate } from 'react-localize-redux';
import * as webConst from '../../../../utils/WebConstant';
import { getPnDispenseHistoryRequest } from '../../../../containers/Medicines/Pn/PnPlanContainer/action';
import { PN_DISPENSE_HISTORY_SUCCESS } from '../../../../containers/Medicines/Pn/PnPlanContainer/Constants';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import daLocale from "date-fns/locale/da";
import moment from "moment";
import InfiniteScroll from 'react-infinite-scroll-component';
import EditIcon from '../../../../images/edit-icon.svg';
// import viewIcon from '../../images/view-icon.svg';

class PNDispenseHistory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			clientId: "",
			clientName: "",
			clientCPR: "",
			clientPhoto: "",
			pnId: "",
			isLoading: false,
			validated: false,
			dispenseForm: "",
			medicineId: "",
			dispenseDetail: {},
			totalStrength: 0,
			dosageAmount: 0,
			isFormLoading: false,
			isPnLoading: false,
			pnDosages:[],
			isLoadingDatatable:false
		};
	}

	componentDidMount() {
		let _this = this;
		let client = webConst.getClient();
		let clientId = client && typeof client !== 'undefined' && typeof client.client_id !== 'undefined' ? client.client_id : 0;
		if (clientId > 0) {
			let clientName = client && typeof client !== 'undefined' && typeof client.client_name !== 'undefined' ? client.client_name : 0;
			let cpr = client && typeof client !== 'undefined' && typeof client.client_socialid !== 'undefined' ? client.client_socialid : 0;
			let photo = client && typeof client !== 'undefined' && typeof client.photo !== 'undefined' ? client.photo : 0;
			this.setState({ clientId: clientId, clientName: clientName, clientCPR: cpr, clientPhoto: photo });
		}
		else {
			webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.select-client-message" />);
			this.props.history.push(routes.HOME_ROUTE);
		}
	}

	componentDidUpdate(prevProps) {
		const { pnSuccess, pnStatus, pnType, pnActiveTab } = this.props;
		
		if (prevProps.pnActiveTab !== "" && prevProps.pnActiveTab !== "pn-dispense-history" && pnActiveTab === "pn-dispense-history") {			
			let params = new URLSearchParams();
			params.append('clinic_id', webConst.getClinicId());
			params.append('client_id', webConst.getClientId());
			this.getActivePnData(params);
		}

		if (pnStatus === 200) {
			if (pnType === PN_DISPENSE_HISTORY_SUCCESS) {
				if (prevProps.pnSuccess !== pnSuccess) {
					this.setState({
						isPnLoading: false
					});
					if (pnSuccess.pn_dosages && pnSuccess.pn_dosages.length) {
						this.setState({ pnDosages: pnSuccess.pn_dosages});
					}
				}
			}
		}

		if (pnStatus === 400) {
			if (this.state.isPnLoading) {
				this.setState({
					isPnLoading: false,
					isFormLoading: false
				});
			}

			if (this.state.pnDosages.length > 0) {
				this.setState({ pnDosages: []});
			}
		}
	}

	getActivePnData = (params) => {
		if (this.state.isPnLoading !== true)
			this.setState({ isPnLoading: true });
		this.props.getPnDispenseHistoryRequest(params);
	}

	render() {
		return (
			<div className="">
				<div className="validi-tabbar-inner-header">
					<div className="">
						<h4 className="validi-subtitle"><Translate id="medicine.pn-dispense-history" /></h4>
					</div>
					<div className="validi-table-filter-box">

					</div>
				</div>
				<div className="validi-table-data-wrapper">
					<div className="table-responsive-md">
						<InfiniteScroll
							dataLength={this.state.pnDosages && this.state.pnDosages.length ? this.state.pnDosages.length : 0}
							next={() => this.getActivePnData}
							hasMore={this.state.isMoreRecord}
							loader={this.state.isPnLoading ? <h4><Translate id="general.loading" /></h4> : ""}
						>
							{this.state.pnDosages && Object.keys(this.state.pnDosages).length ?
								<Table responsive="md">
									<thead>
										<tr>
											<th>#</th>
											<th><Translate id="medicine.dispense-date" /></th>
											<th><Translate id="medicine.dosage-amount" /></th>
											<th><Translate id="general.medicine" /></th>
											<th><Translate id="medicine.dispense-by" /></th>
											<th><Translate id="general.note" /></th>
										</tr>
									</thead>
									<tbody>
										{this.state.pnDosages.map((pn, index) => (
											<tr key={index}>
												<td className="validi-date-text">{index + 1}</td>
												<td className='text-capitalize'>{pn.dispense_date}</td>
												<td className='text-capitalize'>{pn.dosage_amount} {webConst.shortType(pn.medicine_type.medicine_type)}</td>
												<td>{pn.medicine.name}</td>
												<td>{pn.user.name}</td>
												<td className="validi-date-text">{pn.remark}</td>
											</tr>
										))}
									</tbody>
								</Table>
								: ""
							}
						</InfiniteScroll>
						{
							this.state.pnDosages && this.state.pnDosages.length === 0 ?
								<div className='alert alert-danger'>
									<Translate id="medicine.detox-plan"></Translate> <Translate id="alert-message.not-found"></Translate>
								</div>
								: ""
						}
					</div>
				</div>
			</div>
		)
	}
}


PNDispenseHistory.defaultProps = {
	clientId: '',
	pnActiveTab: "",
	pnType: "",
	pnSuccess: "",
	pnFail: "",
	pnStatus: ""
};

PNDispenseHistory.propTypes = {
	clientId: PropTypes.any,
	pnActiveTab: PropTypes.any,
	pnType: PropTypes.any,
	pnSuccess: PropTypes.any,
	pnFail: PropTypes.any,
};

function mapStateToProps(state) {
	return {
		clientId: state.appLayout.clientId ? state.appLayout.clientId : "",
		pnActiveTab: state.pnPlan.pnActiveTab,
		pnSuccess: state.pnPlan.success,
		pnFail: state.pnPlan.fail,
		pnType: state.pnPlan.type,
		pnStatus: state.pnPlan.status
	};
}

const mapDispatchToProps = {
	getPnDispenseHistoryRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(PNDispenseHistory);
