import React, { Component, Fragment } from 'react';
import './SuspendedPN.scss';
import { FormGroup, Label, Input, Form, InputGroup, FormControl, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as routes from '../../../../utils/RouteConstant';
import backArrow from '../../../../images/back-arrow.svg';
import { Translate } from 'react-localize-redux';
import * as webConst from '../../../../utils/WebConstant';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import daLocale from "date-fns/locale/da";
import moment from "moment";
import InfiniteScroll from 'react-infinite-scroll-component';
import EditIcon from '../../../../images/edit-icon.svg';
import { setPnActiveTab, getPnMedicinePlanRequest, getSuspendedPnMedicinePlanHistoryRequest } from '../../../../containers/Medicines/Pn/PnPlanContainer/action';
import { PN_SUSPENDED_PLAN_SUCCESS } from '../../../../containers/Medicines/Pn/PnPlanContainer/Constants';

class SuspendedPN extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPnLoading: true,
			clientId: "",
			clientName: "",
			clientCPR: "",
			clientPhoto: "",
			pnActiveTab: "pn-suspended",
			isLoadedDatatable: 0,
			pns: [],
			pageSize: 20,
			pageNumber: 0,
			editPn: ""
		};
	}

	componentDidMount() {
		let _this = this;
		let client = webConst.getClient();
		let clientId = client && typeof client !== 'undefined' && typeof client.client_id !== 'undefined' ? client.client_id : 0;
		if (clientId > 0) {
			let clientName = client && typeof client !== 'undefined' && typeof client.client_name !== 'undefined' ? client.client_name : 0;
			let cpr = client && typeof client !== 'undefined' && typeof client.client_socialid !== 'undefined' ? client.client_socialid : 0;
			let photo = client && typeof client !== 'undefined' && typeof client.photo !== 'undefined' ? client.photo : 0;
			this.setState({ clientId: clientId, clientName: clientName, clientCPR: cpr, clientPhoto: photo, isPnLoading: true });
		}
		else {
			webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.select-client-message" />);
			this.props.history.push(routes.HOME_ROUTE);
		}
	}

	componentDidUpdate(prevProps) {
		const { pnSuccess, pnStatus, pnType, pnActiveTab } = this.props;
		if (prevProps.pnActiveTab !== "" && prevProps.pnActiveTab !== pnActiveTab && pnActiveTab === "pn-suspended") {
			this.getPns();
		}

		if (pnStatus === 200) {
			if (pnType === PN_SUSPENDED_PLAN_SUCCESS) {
				if (prevProps.pnSuccess !== pnSuccess) {
					this.setState({
						isPnLoading: false,
					});

					if (pnSuccess.pns && pnSuccess.pns.length) {
						this.setState({ pns: pnSuccess.pns });
					}
				}
			}
		}

		if (pnStatus === 400) {
			if (this.state.isPnLoading) {
				this.setState({
					isPnLoading: false,
				});
			}

			if (this.state.pns.length > 0)
				this.setState({ pns: [] });
		}
	}

	getPns = () => {
		let pageNumber = this.state.pageNumber ? this.state.pageNumber : 0;
		pageNumber = pageNumber + 1;
		if ((this.state.totalRecord > this.state.pns.length) || pageNumber == 1) {
			if (!this.state.isPnLoading) {
				this.setState({ 'isPnLoading': true, 'isLoading': true });
			}
			const params = new URLSearchParams();
			params.append('client_id', webConst.getClientId());
			params.append('status', '1');
			params.append('page', pageNumber);
			params.append('limit', this.state.pageSize);
			this.props.getSuspendedPnMedicinePlanHistoryRequest(params);
			this.setState({ pageNumber: pageNumber });
		}
	}

	render() {
		return (
			<div className="">
				<div className="validi-tabbar-inner-header">
					<div className="">
						<h4 className="validi-subtitle"><Translate id="medicine.pn-dispense-history" /></h4>
					</div>
					<div className="validi-table-filter-box">

					</div>
				</div>
				<div className="validi-table-data-wrapper">
					<div className="table-responsive-md">
						<InfiniteScroll
							dataLength={this.state.pns && this.state.pns.length ? this.state.pns.length : 0}
							next={() => this.getPns}
							hasMore={this.state.isMoreRecord}
							loader={this.state.isPnLoading ? <h4><Translate id="general.loading" /></h4> : ""}
						>
							{this.state.pns && Object.keys(this.state.pns).length ?
								<Table responsive="md">
									<thead>
										<tr>
											<th>#</th>
											<th><Translate id="general.doctor" /></th>
											<th><Translate id="general.medicine" /></th>
											<th><Translate id="general.start-date" /></th>
											<th><Translate id="general.total" /></th>
											<th><Translate id="general.created-by" /></th>
											<th><Translate id="general.note" /></th>
										</tr>
									</thead>
									<tbody>
										{this.state.pns.map((pn, index) => (
											<tr key={index}>
												<td className="validi-date-text">{index + 1}</td>
												<td className='text-capitalize'>{pn.doctor.name}</td>
												<td className='text-capitalize'>{pn.medicine.name}</td>
												<td>{pn.start_date}</td>
												<td>{pn.initial_amount} {webConst.shortType(pn.medicine_type.medicine_type)}</td>
												<td className='text-capitalize'>{pn.user.name}</td>
												<td>{pn.note}</td>
											</tr>
										))}
									</tbody>
								</Table>
								: ""
							}
						</InfiniteScroll>
						{
							this.state.pns && this.state.pns.length === 0 ?
								<div className='alert alert-danger'>
									<Translate id="medicine.detox-plan"></Translate> <Translate id="alert-message.not-found"></Translate>
								</div>
								: ""
						}
					</div>
				</div>
			</div>
		)
	}
}


SuspendedPN.defaultProps = {
	clientId: '',
	pnActiveTab: "",
	pnType: "",
	pnSuccess: "",
	pnFail: "",
	pnStatus: ""
};

SuspendedPN.propTypes = {
	clientId: PropTypes.any,
	pnActiveTab: PropTypes.any,
	pnType: PropTypes.any,
	pnSuccess: PropTypes.any,
	pnFail: PropTypes.any,
};

function mapStateToProps(state) {
	return {
		clientId: state.appLayout.clientId ? state.appLayout.clientId : "",
		pnActiveTab: state.pnPlan.pnActiveTab,
		pnSuccess: state.pnPlan.success,
		pnFail: state.pnPlan.fail,
		pnType: state.pnPlan.type,
		pnStatus: state.pnPlan.status
	};
}

const mapDispatchToProps = {
	setPnActiveTab,
	getSuspendedPnMedicinePlanHistoryRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(SuspendedPN);
