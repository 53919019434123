import { MEDICINE_STOCK_SUCCESS, MEDICINE_STOCK_FAIL, ADD_MEDICINE_STOCK_SUCCESS, ADD_MEDICINE_STOCK_FAIL } from './Constants';
import axios from 'axios';
import { withHeader } from '../../../service/ApiConfig';
import * as webConst from '../../../utils/WebConstant';
const axiosDefaults = require('axios/lib/defaults');
axiosDefaults.baseURL = withHeader.baseURL;
axiosDefaults.headers = withHeader.headers;
axiosDefaults.timeout = withHeader.timeOut;

export const getStockRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.GET_STOCK}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    dispatch(stockSuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(stockFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2x
                    dispatch(stockFailResult(error.response.message,error.response.status));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });

    }
};


export const getMedicineStockRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.GET_MEDICINE_STOCK}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    dispatch(stockSuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(stockFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2x
                    dispatch(stockFailResult(error.response.message,error.response.status));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    }
};


export const addStockRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.ADD_MEDICINE_STOCK}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    dispatch(addStockSuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(addStockFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2x
                    dispatch(addStockFailResult(error.response.message,error.response.status));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    }
};

/*
    API RESULT
 */

export const stockSuccessResult = (success, status) => ({
    type: MEDICINE_STOCK_SUCCESS,
    success,
    status
});

export const stockFailResult = (error, status) => ({
    type: MEDICINE_STOCK_FAIL,
    error,
    status
});

export const addStockSuccessResult = (success, status) => ({
    type: ADD_MEDICINE_STOCK_SUCCESS,
    success,
    status
});

export const addStockFailResult = (error, status) => ({
    type: ADD_MEDICINE_STOCK_FAIL,
    error,
    status
});