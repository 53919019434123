import { JOURNAL_TYPE_SUCCESS, JOURNAL_TYPE_FAIL,ADD_JOURNAL_TYPE_SUCCESS, ADD_JOURNAL_TYPE_FAIL,UPDATE_JOURNAL_TYPE_SUCCESS,UPDATE_JOURNAL_TYPE_FAIL,DELETE_JOURNAL_TYPE_SUCCESS,DELETE_JOURNAL_TYPE_FAIL } from './Constants';

const initialState = {
  isLoading: true
};
export default function journalTypeReducer(state = initialState, action) {
  switch (action.type) {
    case JOURNAL_TYPE_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type:action.type
      };
    case JOURNAL_TYPE_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type:action.type
      };
      case ADD_JOURNAL_TYPE_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type:action.type
      };
    case ADD_JOURNAL_TYPE_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type:action.type
      };
    case UPDATE_JOURNAL_TYPE_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type:action.type
      };
    case UPDATE_JOURNAL_TYPE_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type:action.type
      };
    case DELETE_JOURNAL_TYPE_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status,
        type:action.type
      };
    case DELETE_JOURNAL_TYPE_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status,
        type:action.type
      };
    default:
      return state;
  }
}