import { CLIENT_SUCCESS, CLIENT_FAIL,CLIENT_TOOLTIP_SUCCESS,CLIENT_TOOLTIP_FAIL,SAVE_CLIENT_SUCCESS, SAVE_CLIENT_FAIL } from './Constants';
import axios from 'axios';
import { withHeader } from '../../service/ApiConfig';
import * as webConst from '../../utils/WebConstant';
const axiosDefaults = require('axios/lib/defaults');
axiosDefaults.baseURL = withHeader.baseURL;
axiosDefaults.headers = withHeader.headers;
axiosDefaults.timeout = withHeader.timeOut;

export const getClientListRequest = (value) => {
    withHeader.headers.Authorization = webConst.getUserToken();
    return dispatch => {
        axios.get(`${webConst.CLIENT_API}?`+value)
        .then((response) => {
            if(response.status===200)
            {
                dispatch(clientSuccessResult(response.data.data,response.status));
            }
            else{
                dispatch(clientFailResult(response.data.responseMessage,response.status));
            }
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                dispatch(clientFailResult(error.response.data.message,error.response.status));
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        }); 
    }
};

export const getClientTooltipRequest = (value) => {
    return dispatch => {
        axios.get(`${webConst.CLIENT_API}/`+value)
        .then((response) => {
            if(response.status===200)
            {
                dispatch(clientTooltipSuccessResult(response.data.data,response.status));
            }
            else{
                dispatch(clientTooltipFailResult(response.data.responseMessage,response.status));
            }
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                dispatch(clientTooltipFailResult(error.response.data.message,error.response.status));
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        });
    }
};


export const addClientRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.CLIENT_API}`,value)
        .then((response) => {
            if(response.status===200)
            {
                dispatch(saveClientSuccessResult(response.data.data,response.status));
            }
            else{
                dispatch(saveClientFailResult(response.data.message,response.status));
            }
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                dispatch(saveClientFailResult(error.response.data.message,error.response.status));
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        });
    }
};

/*
    API RESULT
 */


export const clientSuccessResult = (success,status) => ({
    type: CLIENT_SUCCESS,
    success,
    status
});

export const clientFailResult = (error,status) => ({
    type: CLIENT_FAIL,
    error,
    status
});

export const clientTooltipSuccessResult = (success,status) => ({
    type: CLIENT_TOOLTIP_SUCCESS,
    success,
    status
});

export const clientTooltipFailResult = (error,status) => ({
    type: CLIENT_TOOLTIP_FAIL,
    error,
    status
});

export const saveClientSuccessResult = (success,status) => ({
    type: SAVE_CLIENT_SUCCESS,
    success,
    status
});

export const saveClientFailResult = (error,status) => ({
    type: SAVE_CLIENT_FAIL,
    error,
    status
});
