import React, { Component, Fragment } from 'react';
import './TimeSlot.scss';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import * as routes from '../../../utils/RouteConstant';
import * as webConst from '../../../utils/WebConstant';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { Cube } from 'react-preloaders2';
import Table from 'react-bootstrap/Table';
import { OverlayTrigger, Overlay, Tooltip, Button, Popover, Modal, Tabs, Tab, Form } from 'react-bootstrap';
import { getTimeSlotRequest, addTimeSlotRequest, updateTimeSlotRequest, deleteTimeSlotRequest } from '../../../containers/Medicines/TimeSlotContainer/action';
import { TIME_SLOT_SUCCESS, TIME_SLOT_FAIL, ADD_TIME_SLOT_SUCCESS, UPDATE_TIME_SLOT_SUCCESS, DELETE_TIME_SLOT_SUCCESS } from '../../../containers/Medicines/TimeSlotContainer/Constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import daLocale from "date-fns/locale/da";
import EditIcon from '../../../images/edit-icon.svg';
import DeleteIcon from '../../../images/delete-icon.svg';

class TimeSlot extends Component {
	constructor(props) {
		super(props);
		this.state = {
			'isLoading': true,
			'timeSlots': [],
			'paged': 1,
			'isMoreRecord': true,
			'clientId': '',
			'timeslot': '',
			'isTimeSlotLoading': true,
			'isFormLoading': false,
			'validated': false,
			'timeSlotForm': '',
			'pageSize': 20,
			'pageNumber': 0,
			'formErrors': [],
			'activeKey': 'view',
			'id': ''
		};

		this.timeSlotForm = React.createRef();
		this.getTimeSlot = this.getTimeSlot.bind(this);
		this.handleChangeDateRange = this.handleChangeDateRange.bind(this);
		this.handleTimeSlot = this.handleTimeSlot.bind(this);
	}

	componentDidMount() {
		this.getTimeSlot();
	}

	componentDidUpdate(prevProps) {
		const { timeSlotSuccess, timeSlotFail, timeSlotStatus, timeSlotType } = this.props;

		if (timeSlotStatus === 200) {
			if (prevProps.timeSlotSuccess !== timeSlotSuccess) {
				if (TIME_SLOT_SUCCESS === timeSlotType) {
					let total = this.props.timeSlotSuccess.data.total;
					this.setState({ timeSlots: [...this.state.timeSlots, ...timeSlotSuccess.data.slots] });
					this.setState({
						totalRecord: total,
						isMoreRecord: true,
						isLoading: false,
						isTimeSlotLoading: false,
						paged: parseInt(this.state.paged) + 1
					});
				}

				if (ADD_TIME_SLOT_SUCCESS === timeSlotType) {
					let timeSlots = this.state.timeSlots;
					timeSlots.unshift(timeSlotSuccess);
					this.handleClearForm();
					this.setState({ timeSlots: timeSlots,activeKey:"view" });
					webConst.toaster(webConst.TOAST_SUCCESS, <><Translate id="time-slot.time-slot" />  <Translate id="alert-message.saved-message" /></>);
				}

				if (UPDATE_TIME_SLOT_SUCCESS === timeSlotType) {
					let timeSlots = this.state.timeSlots.map(item => {
						return item.id === timeSlotSuccess.id ? timeSlotSuccess : item;
					});
					this.handleClearForm();
					this.setState({ timeSlots: timeSlots,activeKey:"view" });
					webConst.toaster(webConst.TOAST_SUCCESS, <><Translate id="time-slot.time-slot" />  <Translate id="alert-message.saved-message" /></>);
				}

				if (DELETE_TIME_SLOT_SUCCESS === timeSlotType) {
					let _this=this;
					this.setState({ pageNumber: 0,timeSlots:[] });
					webConst.toaster(webConst.TOAST_SUCCESS, <><Translate id="time-slot.time-slot" />  <Translate id="alert-message.deleted-message" /></>);
					setTimeout(() => {
						_this.getTimeSlot();
					}, 500);
				}

				if (this.state.isFormLoading) {
					this.setState({
						isFormLoading: false,
						validated: false,
						timeSlot: "",
						id: "",
						activeKey: "view"
					});
				}
			}
		}
		else {
			if (this.state.isTimeSlotLoading) {
				this.setState({
					isLoading: false,
					isTimeSlotLoading: false,
					isMoreRecord: false
				});
				if (TIME_SLOT_FAIL === timeSlotType) {
					this.setState({
						isMoreRecord: false
					});
				}
			}

			if (this.state.isFormLoading) {
				this.setState({
					isFormLoading: false
				});

				if (timeSlotStatus == 422) {
					if (prevProps.timeSlotFail !== timeSlotFail) {
						webConst.toaster(webConst.TOAST_ERROR, <><Translate id="alert-message.form-validition" /></>);
						this.setState({ formErrors: timeSlotFail });
					}
				}
			}
		}
	}

	async getTimeSlot() {
		let pageNumber = this.state.pageNumber + 1;
		if ((this.state.totalRecord > this.state.timeSlots.length) || pageNumber == 1) {
			if (!this.state.isTimeSlotLoading) {
				this.setState({ 'isTimeSlotLoading': true, 'isLoading': true });
			}
			const params = new URLSearchParams();
			params.append('page', pageNumber);
			params.append('limit', this.state.pageSize);
			this.props.getTimeSlotRequest(params);
			this.setState({ pageNumber: pageNumber });
		}
	}


	handleChangeDateRange = (name, value) => {
		this.setState({ [name]: value });
	}

	handleTimeSlot = (event) => {
		event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			this.setState({ "validated": true });
			event.preventDefault();
			event.stopPropagation();
		}
		else {
			const params = new URLSearchParams();
			params.append('id', this.state.id);
			params.append('slot', this.state.timeSlot);
			if (this.state.id > 0)
				this.props.updateTimeSlotRequest(this.state.id, params);
			else
				this.props.addTimeSlotRequest(params);
			this.setState({ "isFormLoading": true });
		}
		this.setState({ "validated": true });
	}

	handleClearForm = (event) => {
		this.timeSlotForm.current.reset();
		this.setState({ "timeSlot": "", "validated": false, formErrors: [], "id": "" });
		this.timeSlotForm.current.classList.remove("was-validated");
	}

	editRow = (slot) => {
		console.log("slot", slot);
		this.setState({
			activeKey: "add",
			id: slot.id,
			timeSlot: slot.slot
		});
	}

	deleteRow = (slot) => {
		confirmAlert({
			title: "Delete time slot",
			message: "Are you sure delete this time slot?",
			buttons: [
				{
					label: "Ja",
					onClick: () => {
						if (slot.id) {
							this.setState({ 'isLoading': true });
							this.props.deleteTimeSlotRequest(slot.id);
						}
					}
				},
				{
					label: "Ingen",
					// onClick: () => alert('Click No')
				}
			],
		});
	}

	render() {
		return (
			<div className="validi-main-layout validi-detox-medicine-layout">
				<div>
					<div className="container">
						<h1 className="main-title"><Translate id="time-slot.title" /></h1>
						<div className="validi-medicine-wrapper">
							<div className="validi-tabbar-wrapper">
								<Tabs id="controlled-tab-example" activeKey={this.state.activeKey} onSelect={(id) => { this.setState({ activeKey: id }) }}>
									<Tab eventKey="view" title={<Translate id="time-slot.title" />}>
										<div className="">
											<div className="validi-tabbar-inner-header">
												<div className="">
													<h4 className="validi-subtitle"><Translate id="time-slot.title" /></h4>
												</div>
												<div className="validi-table-filter-box">
													<form>
														<div className="form-group">
															<input type="search" className="form-control d-none" placeholder="Search…" />
														</div>
													</form>
												</div>
											</div>
											<div className="validi-table-data-wrapper">
												<InfiniteScroll
													dataLength={this.state.timeSlots && this.state.timeSlots.length ? this.state.timeSlots.length : 0}
													next={()=>this.getTimeSlot}
													hasMore={this.state.isMoreRecord}
													loader={this.state.isTimeSlotLoading ? <h4><Translate id="general.loading" /></h4> : ""}
												>
													<Table responsive="md">
														<thead>
															<tr>
																<th>#</th>
																<th><Translate id="time-slot.time-slot" /></th>
																<th><Translate id="general.action" /></th>
															</tr>
														</thead>
														<tbody>
															{this.state.timeSlots.map((slot, index) => (
																<tr key={index}>
																	<td className="validi-date-text">{index + 1}</td>
																	<td>{slot.slot}</td>
																	<td className='text-right'>
																		<div className="validi-active-icons-wrapper">
																			<i onClick={() => this.editRow(slot)}><img src={EditIcon} alt="edit row" /></i>
																			<i onClick={() => this.deleteRow(slot)}><img src={DeleteIcon} alt="delete row" /></i>
																		</div>
																	</td>
																</tr>
															))}
														</tbody>
													</Table>
												</InfiniteScroll>
											</div>
										</div>
									</Tab>
									<Tab eventKey="add" title={this.state.id ? <Translate id="time-slot.update-time-slot" /> : <Translate id="time-slot.add-time-slot" />}>
										<div className="validi-tabbar-inner-header">
											<h4 className="validi-subtitle">{this.state.id ? <Translate id="time-slot.update-time-slot" /> : <Translate id="time-slot.add-time-slot" />}</h4>
										</div>
										<div className="row">
											<div className="col-md-6">
												<Form noValidate validated={this.state.validated} onSubmit={this.handleTimeSlot} ref={this.timeSlotForm}>
													<Form.Group className="form-group" controlId="timeSlot">
														<Form.Label><Translate id="time-slot.time-slot" /></Form.Label>
														<Translate>
															{({ translate }) =>
																<Form.Control
																	type="time"
																	name="timeSlot"
																	value={this.state.timeSlot ? this.state.timeSlot : ""}
																	onChange={(e) => { this.setState({ timeSlot: e.target.value }) }}
																	placeholder={translate("time-slot.time-slot")}
																	required
																/>
															}
														</Translate>
														{this.state.formErrors && this.state.formErrors.slot ? <small className="text-danger">{this.state.formErrors.slot}</small> : ""}
														<Form.Control.Feedback type="invalid"><Translate id="alert-message.required" /></Form.Control.Feedback>
													</Form.Group>
													<div className="">
														<button className="validi-theme-btn">
															{webConst.isLoader(this.state.isFormLoading, <Translate id="general.save" />)}
														</button>
														<button type="button" className="validi-theme-btn clear-btn" onClick={this.handleClearForm}><Translate id="general.clear" /></button>
													</div>
												</Form>
											</div>
										</div>
									</Tab>
								</Tabs>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}


function mapStateToProps(state) {
	return {
		timeSlotSuccess: state.timeSlot.success,
		timeSlotFail: state.timeSlot.fail,
		timeSlotStatus: state.timeSlot.status,
		timeSlotType: state.timeSlot.type
	};
}

const mapDispatchToProps = { getTimeSlotRequest, addTimeSlotRequest, updateTimeSlotRequest, deleteTimeSlotRequest };

export default connect(mapStateToProps, mapDispatchToProps)(TimeSlot);

