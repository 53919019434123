import { TOPIC_SUCCESS, TOPIC_FAIL } from './Constants';
import axios from 'axios';
import { withHeader } from '../../../service/ApiConfig';
import * as webConst from '../../../utils/WebConstant';
const axiosDefaults = require('axios/lib/defaults');
axiosDefaults.baseURL = withHeader.baseURL;
axiosDefaults.headers = withHeader.headers;
axiosDefaults.timeout = withHeader.timeOut;

export const saveTopicRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.ADD_TOPIC}`, value)
            .then((response) => {
                if (response.data.responseCode === 200) {
                    dispatch(topicSaveSuccessResult(response.data.responseBody, response.status));
                }
                else {
                    dispatch(topicSaveFailResult(response.data.responseMessage, response.data.responseCode));
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2x
                    dispatch(topicSaveFailResult(error.response.message,error.response.status));
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
    }
};


/*
    API RESULT
 */

export const topicSaveSuccessResult = (success, status) => ({
    type: TOPIC_SUCCESS,
    success,
    status
});

export const topicSaveFailResult = (error, status) => ({
    type: TOPIC_FAIL,
    error,
    status
});


